import { Button, Select, styled, TextField, Typography } from "@mui/material";

export const PrimaryButton = styled(Button)`
  box-shadow: none;
  font-size: 14px;
  font-weight: 700;
  height: 50px;
  border-radius: 6px !important;
  margin-bottom: 32px;
  color: #ffffff;
  &:hover,
  &:active {
    color: #ffffff;
  }
`;

export const AccountButton = styled(Button)`
  box-shadow: none;
  font-size: 14px;
  font-weight: 700;
  height: 50px;
  border-radius: 6px !important;
  color: #6d6d6d;
  &:hover,
  &:active {
    color: #6d6d6d;
  }
`;

export const TextInput = styled(TextField)`
  input {
    font-size: 14px;
    padding-top: 14px;
    padding-bottom: 14px;
  }
`;

export const SecondaryButton = styled(Button)`
  box-shadow: none;
  width: 70px;
  border-radius: 4px;
  font-size: 14px;
  &:hover,
  &:active {
    background-color: #6d6d6d;
  }
`;

export const IconButton = styled(Button)`
  color: "#111111 !important";
  font-weight: 400;
  font-size: 11px;
  height: 16px;
  padding: 0px;
  &:hover,
  &:active {
    color: #111111;
    background-color: #ffffff;
  }
`;

export const DarkIconButton = styled(Button)`
  color: #fff;
  font-weight: 400;
  font-size: 11px;
  height: 16px;
  padding: 0px;
  &:hover,
  &:active {
    color: #fff;
    background-color: #666;
  }
`;

export const Label = styled(Typography)`
  font-size: 15px;
  font-weight: 700;
  color: "theme.palette.text";
  span {
    font-size: 12px;
    font-weight: 400;
    color: #6d6d6d;
  }
`;

export const SelectInput = styled(Select)`
  margin-top: 12px;
  height: 50px;
  &::placeholder {
    color: #6d6d6d;
    font-size: 14px;
  }
`;
export const OutlinedButton = styled(Button)`
  box-shadow: none;
  padding: 4px;
  border: 1px solid #6d6d6d;
  color: #111111;
  background-color: transparent;
  font-weight: 700;
  font-size: 14px;
  height: 50px;
  flex: 1;
  &:hover,
  &:active {
    color: #111111;
    border: 1px solid #6d6d6d;
  }
`;

export const ContentField = styled(TextField)`
  .MuiInputBase-root {
    background-color: #f1f4ff;
  }
`;
