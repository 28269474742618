import {
  combineReducers,
  configureStore,
  getDefaultMiddleware,
} from "@reduxjs/toolkit";
import { persistReducer } from "redux-persist";
import storageLocal from "redux-persist/lib/storage";
import createSagaMiddleware from "redux-saga";
import { all, call, takeLeading } from "redux-saga/effects";
import {
  authReducer,
  changeTokensSaga,
  CHANGE_TOKENS,
  refreshTokenSaga,
  REFRESH_ACCESS_TOKEN,
} from "./authSlice";
import { refreshUserSaga, REFRESH_USER_SAGA, userReducer } from "./userSlice";

export const HYDRATE_APP = "HYDRATE_APP";
export function* hydrateAppSaga() {
  try {
    yield all([call(refreshUserSaga)]);
  } catch (err) {}
}

function* rootSaga() {
  yield takeLeading(REFRESH_ACCESS_TOKEN, refreshTokenSaga);
  yield takeLeading(REFRESH_USER_SAGA, refreshUserSaga);
  yield takeLeading(CHANGE_TOKENS, changeTokensSaga);
  yield takeLeading(HYDRATE_APP, hydrateAppSaga);
}

export function configureAppStore() {
  const reduxSagaMonitorOptions = {};
  const sagaMiddleware = createSagaMiddleware(reduxSagaMonitorOptions);

  // Create the store with saga middleware
  const middlewares = [sagaMiddleware];

  const reducers = combineReducers({
    auth: persistReducer({ key: "auth", storage: storageLocal }, authReducer),
    user: userReducer,
  });

  const store = configureStore({
    reducer: reducers,
    middleware: [...getDefaultMiddleware(), ...middlewares],
    devTools: process.env.REACT_APP_ENV !== "production",
  });

  sagaMiddleware.run(rootSaga);

  return store;
}
