import { Box, Stack } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { LanguageSelect } from "app/component/LanguageSelect";
import { DarkIconButton, IconButton } from "app/component/styles";
import { useLogout } from "app/hooks/useLogout";
import { useTranslation } from "react-i18next";

export function Header() {
  const { t } = useTranslation("Global");
  const theme = useTheme();
  const { logout } = useLogout();
  const isBright = theme.palette.mode === "light";
  return (
    <Stack
      direction="row"
      alignItems="center"
      justifyContent="space-between"
      sx={{ px: 2, py: 1 }}
    >
      <Stack direction="row">
        {isBright ? (
          <img
            src={require("app/media/IMS.png")}
            alt="logo img"
            style={{
              width: 125,
              height: 24,
            }}
          />
        ) : (
          <img
            src={require("app/media/darkMode.png")}
            alt="logo img"
            style={{
              width: 125,
              height: 24,
            }}
          />
        )}
      </Stack>
      <Stack direction="row" alignItems="center">
        {isBright ? (
          <IconButton
            variant="text"
            style={{ color: "#111" }}
            href="http://teamplayer.co.kr"
            {...{ target: "_blank" }} // MUI의 타입 에러 workaround
            startIcon={
              <img
                src={require("app/media/company.png")}
                style={{ width: 12, height: 11 }}
                alt="company img"
              />
            }
          >
            {t("About Us")}
          </IconButton>
        ) : (
          <DarkIconButton
            variant="text"
            href="http://teamplayer.co.kr"
            {...{ target: "_blank" }}
            startIcon={
              <img
                src={require("app/media/darkCompany.png")}
                style={{ width: 12, height: 11 }}
                alt="company img"
              />
            }
          >
            {t("About Us")}
          </DarkIconButton>
        )}
        <img
          src={require("app/media/Ellipse.png")}
          style={{ width: 2, height: 2, margin: "0 8px" }}
          alt="dot img"
        />
        {isBright ? (
          <IconButton
            variant="text"
            style={{ color: "#111" }}
            startIcon={
              <img
                src={require("app/media/person.png")}
                style={{ width: 10, height: 10 }}
                alt="login img"
              />
            }
            onClick={logout}
          >
            Log out
          </IconButton>
        ) : (
          <DarkIconButton
            variant="text"
            startIcon={
              <img
                src={require("app/media/darkPerson.png")}
                style={{ width: 10, height: 10 }}
                alt="login img"
              />
            }
            onClick={logout}
          >
            Log out
          </DarkIconButton>
        )}
        <Box sx={{ pl: 1 }}>
          <LanguageSelect />
        </Box>
      </Stack>
    </Stack>
  );
}
