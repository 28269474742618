const jp = {
  Global: {
    "About Us": "会社紹介",
  },
  LoginPage: {
    "Please enter your ID": "IDを入力してください",
    "Maximum 100 digits": "「最大100桁です」",
    "Please enter your password": "パスワードを入力してください",
    "Maximum 200 digits": "「最大200桁です」",
    LOGIN: "ログイン",
    "Please enter your": "あなたのログイン情報を",
    "ID and password": "入力してください。",
    "Please enter ID": "IDを入力してください",
    "Please enter password": "パスワードを入力してください。",
    "Signup for newsletter": "会員登録",
    "Login failed": "ログイン失敗",
    "Please check your user ID or PW.": "ユーザ情報をご確認ください。",
    "Waiting to sign up.": "発行待機 中",
    "It will take some time to post your demo account.":
      "デモアカウントの発行には少し時間が必要です。",
    "Issuance takes 1 to 3 days.": "（業務時間中1～3時間）",
  },
  AccountPage: {
    "Email is incorrect.": "電子メールが正しくありません。",
    "Email must be at least 7 characters long.": "メールは7文字以上です",
    "Email must be 100 characters or less.": "メールは 100 文字以下です。",
    "The contact person's name must be 50 characters or less.":
      "担当者名は 50 文字以下です。",
    // 'The contact information must be at least 7 characters long.':
    //   'The contact information must be at least 7 characters long.',
    "The contact information must be 20 characters or less.":
      "担当者連絡先は 20 文字以下です。",
    "Please enter only numbers without - for contact information.":
      "Please enter only numbers without - for contact information.",
    "Please enter all letters, numbers, and special characters for your password":
      "Please enter all letters, numbers, and special characters for your password",
    // 'Password is more than 5 digits long':
    //   'Password is more than 5 digits long',
    // 'Password can be up to 200 characters long':
    //   'Password can be up to 200 characters long',
    // 'Password does not match.': 'Password does not match.',
    "The store (company) name must be at least 2 characters.":
      "店舗(会社)名は2文字以上です。",
    "The store (company) name must be 50 characters or less.":
      "店舗(会社)名は50文字以下です。",
    "The brand store (company) name has at least 1 characters.":
      "ブランド情報は必須です。",
    "The brand store (company) name must be 50 characters or less.":
      "ブランドショップ(会社)名は 50 文字以下です.",
    "Business address must be at least 2 characters long.":
      "Business address must be at least 2 characters long.",
    "Business address must be 100 characters or less.":
      "Business address must be 100 characters or less.",
    "The representative name has more than 2 characters.":
      "The representative name has more than 2 characters.",
    "The representative name must be 50 characters or less.":
      "The representative name must be 50 characters or less.",
    "Representative contact information must be at least 7 characters long.":
      "Representative contact information must be at least 7 characters long.",
    "Representative contact information must be 20 characters or less.":
      "Representative contact information must be 20 characters or less.",
    // 'Please attach the pledge.': 'Please attach the pledge.',
    "ID(Email)  *Required": "* ID(メールアドレス)",
    "Company name  *Required": "* 店舗(会社)名",
    "Brand name  *Required":
      "* ブランド名  (発送時に表示されるブランド名です。)",
    // "Representative name": "Representative name",
    // "Representative contact information": "Representative contact information",
    name: "   担当者(職員)のお名前",
    "phone number": "   担当者（職員）連絡先",
    // "Attachment of pledge": "Attachment of pledge",
    "Signup for newsletter": "ニュースレター会員登録",
    "* is Required": "* 必須入力情報",
    "After you sign up for our newsletter, we will also contact you separately and provide you with a demo account. Issuing a demo account takes 1 to 3 days.":
      "デモアカウントの発行には少し時間が必要です。 （業務時間中1～3時間）",
    "I agree to create a free demo account and send newsletters.":
      "* 無料デモアカウントの発行とニュースレターの発送に同意します。",
    "Please enter only numbers":
      "担当者の連絡先は - なしで数字のみを入力してください。",
    "This is a required option.": "必須オプションです。",
    complete: "登録完了",
    "Welcome!": "ようこそ！",
    "Please log in": "ログインしてください",
    "registration failed": "会員登録に失敗しました",
    "This email address has already been registered.": "既に登録したメールです",
  },
  MessagePage: {
    "Select Channel": "発信番号",
    "Select the sending number to send to": "送信する発信番号の選択",
    [`Please enter messages. “Send various messages using templates and variables.”`]: `内容を入力してください。 テンプレートと変数を使用して、さまざまなメッセージを配信しましょう。`,
    "message templates": "テンプレート",
    "variables message": "自動変更",
    "Please select the automatic change information you want to use from the table.":
      "使用したい自動変更情報を表の中から選んでください。",
    "Auto change is a function used when sending names or coupon numbers individually. - 2":
      "自動変更は、名前またはクーポン番号などを個別に送信する際に使用する機能です。",
    "example)": "例）",
    "① Insert #name# into the content": "① 内容に#name#挿入",
    "② Enter or upload name data such as Tanaka and Hiro in the ‘name’ column (column A) in Excel.":
      "② エクセルに「name」列(A列)に田中様、ヒロ様などの名前データを入力、またはアップロード",
    "③The data in column A is automatically inserted and transmitted in #name#.":
      "③#name#にA列のデータが自動的に挿入されて送信される。",
    "Receive number": "受信番号",
    addition: "追加",
    upload: "アップロード",
    "address book": "住所録",
    "Address book management": "住所録管理",
    "Reserved delivery": "予約送信",
    "Year Month Day": "年度、月、日",
    city: "時",
    minute: "分",
    "Send message": "メッセージ送信",
    "Sent message history": "送信メッセージの履歴",
    [`welcome. Dear #name#.
    If you come to our OOO store today, we will offer you a beer for 100 yen until 8pm.`]: `いらっしゃいませ。#name#様。 
    本日、OOO店にご来店いただきましたら、20時までにビールを100円でご提供します。`,
    [`"congratulations!
    “If you receive this message, come to the OOO store today and you will receive a free plate of yakitori.”`]: `おめでとうございます！ 
    このメールを受信された方は、本日OOO店にお越しになりましたら、焼き鳥を1皿無料で差し上げます。`,
    [`Thank you for visiting us today.
    Did you like the food?
    We will continue to do our best to provide delicious and high quality food.`]: `本日はご来店いただきありがとうございます。
    料理はお口に合いましたか？
    これからもおいしくて質の高い料理を提供できるように最善を尽くします。`,
    attachment: "添付ファイル",
    Preview: "プレビュー",
    "phone number": "電話番号（数字のみ入力）",
    "phone number-v2": "電話番号",
    reset: "アドレス行の追加",
    "Send history": "発送メッセージ履歴",
    "Only messages sent in the last 30 days will be displayed.":
      "* 過去1年間に送信されたメッセージのみが表示可能",
    "send date": "転送日",
    attach: "内容",
    result: "転送結果",
    "1day": "1週間",
    "15day": "15日",
    "1month": "1ヶ月",
    "export to excel": "Excelダウンロード",
    close: "照会",
    Requested: "リクエストされました",
    "Total ": "合計",
    " items": "件",
    "Please enter only numbers.": "- なしで数字だけを入力してください。",
    "Please enter your mobile phone number":
      "「携帯電話番号を入力してください。」",
    "Please write within 15 digits.": "- なしの数字だけが 15 桁です。",
    "Please enter your message.": "内容を入力してください",
    "Maximum 400 characters": "「最大400文字です」",
    "Please enter after the current time": "現在時刻以降を入力してください",
    "Please choose a date": "日付を選択してください",
    "Transfer completed": "伝送 完了",
    "Transfer failed": "伝送 失敗",
    "Transfer resereved.": "伝送 予約",
    "Please enter the variable": "変数を入力してください",
    "Please enter your contact information.": "連絡先を入力してください。",
    "You can enter up to 100 contacts.":
      "「最大100個の連絡先を入力できます。」",
    "Please select a sender number": "発信番号を選択してください",
    " Contact": "番の連絡先",
  },
};

export default jp;
