import { Chip, Stack, styled, TextField } from "@mui/material";
import produce from "immer";
const StyledChip = styled(Chip)`
  min-width: 154px;
  .MuiChip-root {
    width: 70%;
    position: relative;
  }

  .MuiChip-deleteIcon {
    position: absolute;
    left: 12px;
    color: #333333;
    width: 16px;
    height: 16px;
  }

  .MuiChip-label {
    padding-right: 2px;
  }
`;

export function PhoneChip({ form, variableOn, contact, index }: any) {
  const contactList = form.watch("msgs");
  function removeContact() {
    const newList = produce(contactList, (draft: any) => {
      draft.splice(index, 1);
    });
    form.setValue("msgs", newList);
  }

  return variableOn ? (
    <Stack direction="row" spacing={1.5} sx={{ mb: 2 }}>
      <Stack alignItems="center" sx={{ minWidth: 140 }}>
        <StyledChip
          label={`${contact.receiver}`}
          onDelete={removeContact}
          sx={{
            width: 152,
            height: 35,
          }}
        />
      </Stack>
      {["varName", "var1", "var2", "var3", "var4"].map(name => {
        const pathKey = `msgs[${index}].${name}`;
        return (
          <Stack key={name} alignItems="center" sx={{ minWidth: 140 }}>
            <TextField size="small" inputProps={form.register(pathKey)} />
          </Stack>
        );
      })}
    </Stack>
  ) : (
    <StyledChip
      label={contact.receiver}
      onDelete={removeContact}
      sx={{
        width: 152,
        height: 35,
        mr: 2,
        mb: 1.5,
      }}
    />
  );
}
