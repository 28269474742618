import {
  List,
  ListItem,
  Stack,
  Typography,
  styled,
  Button,
} from "@mui/material";
import { useTranslation } from "react-i18next";

export function ListHeader({ width = 140, props = {} }) {
  const { t } = useTranslation("MessagePage");
  const addressList = [
    { id: "phone", label: t("phone number-v2"), align: "center" },
    { id: "name", label: "#name#", align: "center" },
    { id: "one", label: "#1#", align: "center" },
    { id: "two", label: "#2#", align: "center" },
    { id: "three", label: "#3#", align: "center" },
    { id: "four", label: "#4#", align: "center" },
  ];

  return (
    <List sx={{ minWidth: 600, color: "#111111" }}>
      <ListItem {...props}>
        {addressList.map((it, index) => (
          <Stack key={it.id} sx={{ minWidth: width }} alignItems="center">
            <Typography key={it.id} sx={{ fontWeight: 700, fontSize: 13 }}>
              {it.label}
            </Typography>
          </Stack>
        ))}
      </ListItem>
    </List>
  );
}

export const AddressIconButton = styled(Button)`
  color: #111111;
  background-color: #ffffff;
  font-size: 14px;
  font-weight: 400;
  &:hover,
  &:active {
    color: #111111;
    background-color: #ffffff;
  }
`;
export const DarkAddressIconButton = styled(Button)`
  color: #fff;
  background-color: transparent;
  font-size: 14px;
  font-weight: 400;
  &:hover,
  &:active {
    color: #fff;
    background-color: transparent;
  }
`;

export const AddressContent = styled(Stack)`
  border: 1px solid #000000;
  border-radius: 6px;
  background-color: #ffffff;
  color: #ffffff;
  max-width: 700px;
  height: 50%;
  overflow-x: scroll;
  ::-webkit-scrollbar {
    display: none;
  }
`;
