/**
 * index.tsx
 *
 * This is the entry file for the application, only setup and boilerplate
 * code.
 */

import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";

import * as React from "react";
import * as ReactDOM from "react-dom";
import "./i18n/i18n";
// Use consistent styling
// import 'sanitize.css/sanitize.css';

// Import root app

import { HelmetProvider } from "react-helmet-async";

import reportWebVitals from "reportWebVitals";

// Initialize languages
import { App } from "app/App";
import { enableES5 } from "immer";

import { configureAppStore } from "app/store/configureStore";
import { Provider as ReduxProvider } from "react-redux";
import { persistStore } from "redux-persist";
import { PersistGate } from "redux-persist/integration/react";

const store = configureAppStore();
const persistor = persistStore(store);

// For IE 11
enableES5();

const MOUNT_NODE = document.getElementById("root") as HTMLElement;

ReactDOM.render(
  <HelmetProvider>
    <React.StrictMode>
      <ReduxProvider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <App />
        </PersistGate>
      </ReduxProvider>
    </React.StrictMode>
  </HelmetProvider>,
  MOUNT_NODE,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
