import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { Divider, MenuItem, Stack } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { ApiResultModal } from "app/component/ApiResultHandler";
import { Label, PrimaryButton, SelectInput } from "app/component/styles";
import { useAuthAxios } from "app/hooks/useAuthAxios";
import { userSelector } from "app/store/userSlice";
import _ from "lodash";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { maxByteLengthYupTestObj } from "utils/helper";
import * as Yup from "yup";
import { useModalToggle } from "../../component/Modal";
import {
  FormErrorModal,
  useYupValidationResolver,
} from "../../hooks/useYupValidationResolver";
import { Header } from "./Header";
import { ImageInput } from "./ImageInput";
import MessageContent from "./MessageContent";
import MessageDrawer from "./MessageDrawer";
import HistoryButton from "./MessageHistory";
import { PhoneChipList } from "./PhoneChipList";
import { ReservationInput } from "./ReservationInput";
import { SingleContactForm } from "./SingleContactForm";
import TemplateButton from "./TemplateButton";
import VariableButton from "./VariableButton";
import { useDeepCompareEffect } from "react-use";
import { useTranslation } from "react-i18next";

const ArrowIcon = (props: any) => {
  const { style, ...otherProps } = props;
  const colorStyle = {
    color: "#333333",
  };
  const styles = { ...style, ...colorStyle };
  return <KeyboardArrowDownIcon {...otherProps} style={styles} />;
};

const fErrMsg =
  (msg: any, t) =>
  ({ path }: any) => {
    const [rowNum] = _.trimStart(path, "msgs[").split("]");
    return `[${+rowNum + 1}${t(" Contact")}] ${msg}`;
  };

const variables = [
  { pattern: "#name#", key: "varName" },
  { pattern: "#1#", key: "var1" },
  { pattern: "#2#", key: "var2" },
  { pattern: "#3#", key: "var3" },
  { pattern: "#4#", key: "var4" },
];

export function getMsgsSchema(values: any, variableOn: boolean, t) {
  const schema: any = {
    receiver: Yup.string()
      .matches(/^[0-9]*$/, fErrMsg(t("Please enter only numbers."), t))
      .required(fErrMsg(t("Please enter your mobile phone number"), t))
      .typeError(fErrMsg(t("Please enter your mobile phone number"), t))
      .max(15, fErrMsg(t("Please write within 15 digits."), t)),
  };

  for (let v of variables) {
    if (values.template.match(v.pattern) && variableOn) {
      schema[v.key] = Yup.string()
        .typeError(fErrMsg(`${v.pattern} ${t("Please enter the variable")}`, t))
        .min(1, fErrMsg(`${v.pattern} ${t("Please enter the variable")}`, t))
        .test(maxByteLengthYupTestObj(`${v.pattern} exceeds 20 bytes`, 20))
        .required(fErrMsg(`${v.pattern} ${t("Please enter the variable")}`, t));
    }
  }

  const msgsSchema = Yup.array()
    .of(Yup.object().shape(schema))
    .min(1, t("Please enter your contact information."))
    .max(100, t("You can enter up to 100 contacts."));
  return msgsSchema;
}

export default function MessagePage() {
  const theme = useTheme();
  const isBright = theme.palette.mode === "light";
  const { on: variableOn, toggle: toggleVariableMode } = useModalToggle();
  const { t } = useTranslation("MessagePage");

  const resolver = useYupValidationResolver(
    Yup.lazy(obj => {
      const msgsSchema = getMsgsSchema(obj, variableOn, t);
      const formSchema: any = {
        sender: Yup.string().required(t("Please select a sender number")),
        template: Yup.string()
          .required(t("Please enter your message."))
          .min(1, "Please enter your message.")
          .test(maxByteLengthYupTestObj(t("Maximum 400 characters"), 400)),
        msgs: msgsSchema,
      };
      if (obj.sendAt) {
        formSchema.sendAt = Yup.date()
          .required()
          .min(new Date(), "Please enter after the current time")
          .typeError("Please choose a date");
      }
      return Yup.object().shape(formSchema);
    }),
  );

  const form = useForm<any>({
    mode: "onSubmit",
    resolver,
    defaultValues: {
      sender: "",
      template: "",
      msgs: [],
      images: [],
    },
    shouldUnregister: false,
  });
  const user = useSelector(userSelector);
  useDeepCompareEffect(() => {
    if (user) {
      form.setValue("sender", _.get(user, "phones[0].phone", ""));
    }
  }, [user]);

  const [postResult, post] = useAuthAxios({
    method: "POST",
    url: "/messages",
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });

  const onSubmit = form.handleSubmit(function (values) {
    const payload = {
      sender: values.sender,
      template: values.template,
      msgs: values.msgs.map(m => ({
        receiver: `+${m.receiver}`,
        nameVar: m.varName || "",
        firstVar: m.var1 || "",
        secondVar: m.var2 || "",
        thirdVar: m.var3 || "",
        fourthVar: m.var4 || "",
      })),
      sendAt: values.sendAt,
    };

    const data = new FormData();
    data.append("payload", JSON.stringify(payload));

    for (let img of values.images) {
      data.append("files", img.file);
    }

    post({ data });
  });

  const template = form.watch("template");
  useEffect(() => {
    for (let v of variables) {
      if (template.includes(v.pattern)) {
        toggleVariableMode(true);
      }
    }
  }, [template, toggleVariableMode]);
  const msgs = form.watch("msgs");
  const images = form.watch("images");

  return (
    <Stack
      sx={{
        maxWidth: 842,
        margin: "auto",
        p: 2,
        background: isBright ? "#ffffff" : "#1E1E1E",
        positon: "relative",
      }}
      spacing={2}
    >
      <Header />
      <Divider
        sx={{
          boxShadow: "0px 4px 3px 0px #00000012",
          border: isBright ? "2px solid #000" : "2px solid #fff",
          m: "0px !important",
        }}
      />
      <Stack sx={{ p: 2 }}>
        <Label>{t("Select Channel")}</Label>
        <SelectInput
          value={form.watch("sender")}
          onChange={e => form.setValue("sender", e.target.value)}
          displayEmpty
          IconComponent={ArrowIcon}
          // renderValue={(selected: any) => {
          //   if (selected.length === 0) {
          //     // return t("送信する発信番号の選択");
          //     return `${user?.brandName || ''} (${user?.companyName || ''})`;
          //   }
          //   return fPhone(selected);
          // }}
        >
          {_.map(user.phones, c => (
            <MenuItem key={c.id} value={c.phone} sx={{ height: 40 }}>
              {`${user?.brandName || ""} (${user?.companyName || ""})`}
            </MenuItem>
          ))}
        </SelectInput>

        <MessageContent form={form} template={template} />
        <MessageDrawer form={form} template={template} />
        <Stack direction="row" spacing={2} sx={{ pt: 2, flex: 1 }}>
          <TemplateButton form={form} />
          <VariableButton
            variableOn={variableOn}
            toggleVariableMode={toggleVariableMode}
          />
        </Stack>
        <Stack direction="row" spacing={2} sx={{ pt: 2 }} alignItems="center">
          <ImageInput form={form} />
        </Stack>
        <Divider sx={{ mt: 4 }} />
        <SingleContactForm contactList={msgs} contactListForm={form} />
        <Stack direction="row" spacing={1} sx={{ py: 1 }}>
          {/* <ExcelUploadButton form={form} variableOn={variableOn} />
          <AddressListButton form={form} />
          <AddressManagementButton /> */}
        </Stack>
        <PhoneChipList variableOn={variableOn} form={form} />
        <Stack sx={{ pt: 4 }}>
          <ReservationInput form={form} />
        </Stack>
        <Divider sx={{ mb: 4 }} />
        <PrimaryButton sx={{ mb: 2 }} variant="contained" onClick={onSubmit}>
          {t("Send message")}
        </PrimaryButton>
        <ApiResultModal
          apiResult={postResult}
          successTitle={t("Requested")}
          successCode={201}
          onSuccessClose={() => {
            for (let image of images) {
              image.input.value = "";
            }
            form.reset({
              sender: _.get(user, "phones[0].phone", ""),
              template: "",
              msgs: [],
              images: [],
            });
            setTimeout(() => {
              window.scrollTo(0, 0);
            }, 50);
          }}
        />
        <FormErrorModal form={form} />
        <HistoryButton />
      </Stack>
    </Stack>
  );
}
