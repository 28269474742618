import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import {
  Checkbox,
  Divider,
  FormControlLabel,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import { ApiResultModal } from "app/component/ApiResultHandler";
import { LanguageSelect } from "app/component/LanguageSelect";
import { PrimaryButton, TextInput } from "app/component/styles";
import { useAxios } from "app/hooks/useAuthAxios";
import {
  ErrorMsg,
  useYupValidationResolver,
} from "app/hooks/useYupValidationResolver";
import _ from "lodash";
import { useMemo } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { randomString } from "utils/helper";
import * as Yup from "yup";

function AccountPage() {
  const { t } = useTranslation("AccountPage");
  const navigate = useNavigate();

  const [postUserResult, postUser] = useAxios({
    method: "POST",
    url: "/users/",
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
  const randomPassword = useMemo(() => `${randomString(20)}@#$!@$#`, []);

  const resolver = useYupValidationResolver(
    Yup.object().shape({
      email: Yup.string()
        .email(t("Email is incorrect."))
        .min(7, t("Email must be at least 7 characters long."))
        .max(100, t("Email must be 100 characters or less.")),
      name: Yup.string()
        .max(50, t("The contact person's name must be 50 characters or less."))
        .nullable(),
      phone: Yup.string().when({
        is: (value: any) => !!value,
        then: schema =>
          schema
            .matches(/^\d+$/, "Please enter only numbers")
            .max(
              20,
              t("The contact information must be 20 characters or less."),
            ),
        otherwise: schema => schema.nullable(),
      }),
      // password: Yup.string().when({
      //   is: (value: any) => !!value,
      //   then: schema =>
      //     schema.min(1, 'TODO').max(200, '「パスワードは最大200桁です」'),
      //   otherwise: schema => schema.nullable(),
      // }),
      // passwordConfirm: Yup.string().when('password', {
      //   is: (value: any) => !!value,
      //   then: schema =>
      //     schema.oneOf([Yup.ref('password')], 'パスワードが一致しません'),
      //   otherwise: schema => schema.nullable(),
      // }),
      companyName: Yup.string()
        .min(2, t("The store (company) name must be at least 2 characters."))
        .max(50, t("The store (company) name must be 50 characters or less.")),
      brandName: Yup.string()
        .max(
          50,
          t("The brand store (company) name must be 50 characters or less."),
        )
        .min(1, t("The brand store (company) name has at least 1 characters.")),
      didAgreedToTerms: Yup.boolean().oneOf(
        [true],
        "This is a required option.",
      ),
    }),
  );
  const form = useForm<any>({
    mode: "onTouched",
    resolver,
    defaultValues: {
      email: "",
      name: "",
      phone: "",
      password: randomPassword,
      passwordConfirm: randomPassword,
      companyName: "",
      brandName: "",
      didAgreedToTerms: false,
    },
    shouldUnregister: false,
  });

  const handleSubmit = form.handleSubmit(values => {
    const payload = {
      email: values.email,
      name: values.name || undefined,
      phone: values.phone || undefined,
      password: values.password,
      companyName: values.companyName,
      brandName: values.brandName || undefined,
    };

    const data = new FormData();
    data.append("payload", JSON.stringify(payload));

    postUser({ data });
  });
  const { errors, touchedFields: touched } = form.formState;
  const didAgreedToTerms = form.watch("didAgreedToTerms");
  return (
    <Stack
      sx={{
        maxWidth: 842,
        margin: "auto",
        p: 2,
        background: "#ffffff",
        height: "100%",
        overflowY: "scroll",
        "::-webkit-scrollbar": {
          display: "none",
        },
      }}
    >
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        sx={{ pt: 2, pb: 4 }}
      >
        <Stack direction="row" spacing={1} alignItems="center">
          <IconButton onClick={() => navigate("/login/")}>
            <ArrowBackIosNewIcon />
          </IconButton>

          <Typography sx={{ fontSize: 20, fontWeight: 700 }}>
            {t("Signup for newsletter")}
          </Typography>
        </Stack>
        <LanguageSelect />
      </Stack>

      <Stack spacing={1.5} sx={{ mb: 4 }}>
        <Stack direction="row" justifyContent="space-between" flexWrap="wrap">
          <Typography sx={{ color: "#5818DF", fontSize: 12 }}>
            {t("* is Required")}
          </Typography>

          <Typography sx={{ fontSize: 12, maxWidth: 400 }}>
            {t(
              "After you sign up for our newsletter, we will also contact you separately and provide you with a demo account. Issuing a demo account takes 1 to 3 days.",
            )}
          </Typography>
        </Stack>
        <TextInput
          placeholder={t("ID(Email)  *Required")}
          inputProps={form.register("email")}
          error={Boolean(touched.email && errors.email)}
          helperText={_.get(errors, "email.message", "") as string}
        ></TextInput>
        {/* <TextInput
          placeholder="* パスワード"
          inputProps={form.register('password')}
          error={Boolean(touched.password && errors.password)}
          helperText={_.get(errors, 'password.message', '') as string}
          type={'password'}
        ></TextInput>
        <TextInput
          placeholder="* パスワード確認"
          inputProps={form.register('passwordConfirm')}
          error={Boolean(touched.passwordConfirm && errors.passwordConfirm)}
          helperText={_.get(errors, 'passwordConfirm.message', '') as string}
          type={'password'}
        ></TextInput> */}
        <TextInput
          placeholder={t("Company name  *Required")}
          inputProps={form.register("companyName")}
          error={Boolean(touched.companyName && errors.companyName)}
          helperText={_.get(errors, "companyName.message", "") as string}
        ></TextInput>
        <TextInput
          placeholder={t("Brand name  *Required")}
          inputProps={form.register("brandName")}
          error={Boolean(touched.brandName && errors.brandName)}
          helperText={_.get(errors, "brandName.message", "") as string}
        ></TextInput>

        <TextInput
          placeholder={t("name")}
          inputProps={form.register("name")}
          error={Boolean(touched.name && errors.name)}
          helperText={_.get(errors, "name.message", "") as string}
        ></TextInput>
        <TextInput
          placeholder={t("phone number")}
          inputProps={form.register("phone")}
          error={Boolean(touched.phone && errors.phone)}
          helperText={_.get(errors, "phone.message", "") as string}
        ></TextInput>
      </Stack>
      <Divider />
      <Stack sx={{ pt: "16px" }}>
        <FormControlLabel
          control={
            <Checkbox
              size="small"
              checked={didAgreedToTerms}
              onChange={() => {
                form.setValue("didAgreedToTerms", !didAgreedToTerms);
              }}
            />
          }
          label={t(
            "I agree to create a free demo account and send newsletters.",
          )}
        />
        <ErrorMsg form={form} name="didAgreedToTerms" />
      </Stack>

      <Stack spacing={1.5} sx={{ mt: 4 }}>
        <PrimaryButton variant="contained" onClick={handleSubmit}>
          {t("complete")}
        </PrimaryButton>
        <ApiResultModal
          apiResult={postUserResult}
          successCode={201}
          successTitle={t("Welcome!")}
          successContent={t("Please log in")}
          onSuccessClose={() => navigate("/login")}
          failTitle={t("registration failed")}
          ErrorCodeTable={{
            RESOURCE_CONLFICT: {
              content: t("This email address has already been registered."),
            },
          }}
        />
      </Stack>
    </Stack>
  );
}

export default AccountPage;
