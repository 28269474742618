import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useUpdateEffect } from "react-use";
import _ from "lodash";
import { authActions, selectAccessToken } from "app/store/authSlice";
import { userActions } from "app/store/userSlice";
import { useAxios } from "./useAuthAxios";

export function useLogout() {
  const dispatch = useDispatch();
  const accessToken = useSelector(selectAccessToken);

  const [deleteTokenResult, deleteToken] = useAxios(
    {
      method: "DELETE",
      url: `/v1/tokens/`,
      data: { access_token: accessToken },
    },
    { manual: true },
  );

  const navigate = useNavigate();
  const init = useCallback(
    function () {
      const authAction = authActions.changeTokens({
        accessToken: "",
        refreshToken: "",
      });
      dispatch(authAction);

      const userAction = userActions.initUser();
      dispatch(userAction);

      navigate("/login");
    },
    [dispatch, navigate],
  );

  const status =
    _.get(deleteTokenResult, "response.status") ||
    _.get(deleteTokenResult, "error.response.status");
  const loading = _.get(deleteTokenResult, "loading");
  useUpdateEffect(() => {
    if (!loading && typeof status === "number") {
      init();
    }
  }, [init, status, loading]);

  return { logout: () => deleteToken() };
}
