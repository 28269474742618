import React from "react";
import { Helmet } from "react-helmet-async";
import { Stack, Typography } from "@mui/material";

function Page404() {
  return (
    <>
      <Helmet title="404 Error" />
      <Stack sx={{ background: "#ffffff", width: "100%", height: "100%" }}>
        <Typography component="h1" variant="h1" align="center" gutterBottom>
          404
        </Typography>
        <Typography component="h2" variant="h5" align="center" gutterBottom>
          Page not found.
        </Typography>
        <Typography component="h2" variant="body1" align="center" gutterBottom>
          The page you are looking for might have been removed.
        </Typography>
      </Stack>
    </>
  );
}

export default Page404;
