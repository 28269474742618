import { Stack } from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { Label, TextInput } from "app/component/styles";
import { startOfDay } from "date-fns";
import koLocale from "date-fns/locale/ko";
import { formatDate } from "utils/helper";
import { useModalToggle } from "../../component/Modal";
import { useTranslation } from "react-i18next";

export function ReservationInput({ form }) {
  const { t } = useTranslation("MessagePage");

  const date = form.watch("sendAt");
  const hours = date?.getHours();
  const minutes = date?.getMinutes();
  const { on, open, close } = useModalToggle();
  return (
    <>
      <Label>{t("Reserved delivery")}</Label>
      <Stack direction="row" spacing={1} sx={{ pt: 1.5, mb: 4 }}>
        <LocalizationProvider
          dateAdapter={AdapterDateFns}
          adapterLocale={koLocale}
        >
          <DatePicker
            open={on}
            onOpen={open}
            onClose={close}
            minDate={new Date()}
            renderInput={props => {
              return (
                <>
                  <TextInput
                    placeholder={t("Year Month Day")}
                    sx={{ flex: 2 }}
                    onClick={open}
                    color="primary"
                    value={date ? formatDate(date, "yyyy.MM.dd") : null}
                    InputProps={{
                      inputProps: {
                        style: { textAlign: "right" },
                      },
                    }}
                    inputRef={props.inputRef}
                  />
                </>
              );
            }}
            label="DateTimePicker"
            value={date}
            onChange={newValue => {
              form.setValue(
                "sendAt",
                newValue
                  ? startOfDay(newValue) > new Date()
                    ? startOfDay(newValue)
                    : new Date()
                  : null,
              );
            }}
          />
        </LocalizationProvider>
        <TextInput
          placeholder={t("hour")}
          sx={{ flex: 1 }}
          value={typeof hours === "number" ? hours : ""}
          disabled={!date}
          InputProps={{
            inputProps: {
              style: { textAlign: "right" },
            },
          }}
          onChange={e => {
            const copy = new Date(date);
            if (e.target.value === "") {
              copy.setHours(0);
              form.setValue("sendAt", copy);
            }
            const num = parseInt(e.target.value);
            if (!isNaN(num) && num < 24) {
              copy.setHours(num);
              form.setValue("sendAt", copy);
            }
            if (num >= 24) {
              alert("0〜23の数字を入力してください");
            }
          }}
        />
        <TextInput
          placeholder={t("minute")}
          sx={{ flex: 1 }}
          value={typeof minutes === "number" ? minutes : ""}
          InputProps={{
            inputProps: {
              style: { textAlign: "right" },
            },
          }}
          disabled={!date}
          onChange={e => {
            const copy = new Date(date);
            if (e.target.value === "") {
              copy.setMinutes(0);
              form.setValue("sendAt", copy);
            }
            const num = parseInt(e.target.value);
            if (!isNaN(num) && num < 60) {
              copy.setMinutes(num);
              form.setValue("sendAt", copy);
            }
            if (num >= 60) {
              alert("0〜59の数字を入力してください");
            }
          }}
        />
      </Stack>
    </>
  );
}
