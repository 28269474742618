import { Stack } from "@mui/material";
import { CountrySelect } from "app/component/CountrySelect";
import { Label, SecondaryButton, TextInput } from "app/component/styles";
import produce from "immer";
import _ from "lodash";
import { useForm } from "react-hook-form";
import * as Yup from "yup";
import { useYupValidationResolver } from "../../hooks/useYupValidationResolver";
import { useTranslation } from "react-i18next";

export function SingleContactForm({ contactList, contactListForm }: any) {
  const { t } = useTranslation("MessagePage");

  const resolver = useYupValidationResolver(
    Yup.object().shape({
      phone: Yup.string()
        .matches(/^[0-9]*$/, t("Please enter only numbers."))
        .required(t("Please enter your mobile phone number"))
        .typeError(t("Please enter your mobile phone number"))
        .max(15, t("Please write within 15 digits.")),
    }),
  );

  const form = useForm<any>({
    mode: "onChange",
    resolver,
    defaultValues: {
      phone: "",
      countryCode: "81",
    },
    shouldUnregister: false,
  });

  const onSubmit = form.handleSubmit(function ({ phone, countryCode }) {
    const newList = produce(contactList, (draft: any) => {
      draft.push({
        receiver: `${countryCode}${phone}`,
      });
    });
    contactListForm.setValue("msgs", newList);
    form.reset();
  });
  const { errors, touchedFields: touched }: any = form.formState;

  return (
    <>
      <Label sx={{ pt: 4, pb: 1.5 }}>{t("Receive number")}</Label>
      <Stack direction="row" flexWrap="wrap">
        <CountrySelect
          onSelect={code => {
            form.setValue("countryCode", code.internationalNumber);
          }}
          value={form.watch("countryCode")}
        />
        <TextInput
          sx={{
            bgcolor: "theme.pallette.background",
            flex: 1,
            minWidth: "200px",
          }}
          placeholder={t("phone number")}
          inputProps={form.register("phone")}
          InputProps={{
            endAdornment: (
              <SecondaryButton
                variant="contained"
                color="secondary"
                onClick={onSubmit}
              >
                {t("Add.")}
              </SecondaryButton>
            ),
          }}
          error={Boolean(touched.phone && errors.phone)}
          helperText={_.get(errors, "phone.message")}
        />
      </Stack>
    </>
  );
}
