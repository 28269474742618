import format from "date-fns/format";
import produce from "immer";
import _ from "lodash";
import { useLocation } from "react-router";

(window as any).logLevel = process.env.REACT_APP_LOG_LEVEL || "log";
const logLevelTable = {
  debug: 0,
  log: 1,
  error: 2,
};
export const logger = (function () {
  const logger: any = {};
  if (process.env.REACT_APP_ENV === "production") {
    logger.log = () => undefined;
    logger.debug = () => undefined;
    logger.error = () => undefined;
  } else {
    logger.debug = (...args) => {
      if (logLevelTable[(window as any).logLevel] <= logLevelTable["debug"]) {
        console.info(...args);
      }
    };
    logger.log = (...args) => {
      if (logLevelTable[(window as any).logLevel] <= logLevelTable["log"]) {
        console.log(...args);
      }
    };
    logger.error = (...args) => {
      if (logLevelTable[(window as any).logLevel] <= logLevelTable["error"]) {
        console.error(...args);
      }
    };
  }

  return logger;
})();

export const comma = number => {
  return (number || 0).toLocaleString("ko-KR");
};

export function timeStrToSemanticStr(str) {
  return _.split(str, ":")
    .map((it, index) => {
      const time = +it;
      if (!time) return null;
      else if (index === 0) return `${time}시간`;
      else if (index === 1) return `${time}분`;

      return null;
    })
    .filter(_.identity)
    .join(" ");
}

export function useQuery(): any {
  return new URLSearchParams(useLocation().search);
}

export function formatDate(date, form = "yyyy.MM.dd") {
  let result;
  try {
    if (!!date) {
      result = format(new Date(date), form);
    } else {
      result = "";
    }
  } catch (err) {
    console.error(err);
  }

  return result;
}

export const propSelector = (selector, defaultValue?) => {
  return props =>
    !!_.get(props, selector) ? _.get(props, selector) : defaultValue;
};

export const loadScript = (src, id, append_to = document.head) => {
  return new Promise((resolve, reject) => {
    try {
      let js: any = document.getElementById(id);
      if (!!js) {
        return resolve(js);
      }

      js = document.createElement("script");

      js.id = id;
      js.type = "text/javascript";
      js.src = src;
      js.onload = resolve;

      append_to.appendChild(js);
    } catch (err) {
      reject(err);
    }
  });
};

export function defaults<T>(left: T, right: T): T {
  const copy = produce(left, draft => {
    return _.defaults(draft, right);
  });

  return copy;
}

export function defaultsDeep<T>(left: T, right: T): T {
  const copy: T = produce(left, draft => {
    return _.defaultsDeep(draft, right);
  });

  return copy;
}

export function dim(
  obj: any,
  key: string,
  size: "xl" | "lg" | "md" | "sm" | "xs",
) {
  const field = _.get(obj, key);

  const xl =
    _.get(field, "xl") |
    _.get(field, "lg") |
    _.get(field, "md") |
    _.get(field, "sm") |
    _.get(field, "xs");
  const lg =
    _.get(field, "lg") |
    _.get(field, "md") |
    _.get(field, "sm") |
    _.get(field, "xs");
  const md = _.get(field, "md") | _.get(field, "sm") | _.get(field, "xs");
  const sm = _.get(field, "sm") | _.get(field, "xs");
  const xs = _.get(field, "xs");

  const table = {
    xl,
    lg,
    md,
    sm,
    xs,
  };

  return table[size];
}

export function parseJwt(token) {
  var base64Url = token.split(".")[1];
  var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  var jsonPayload = decodeURIComponent(
    atob(base64)
      .split("")
      .map(function (c) {
        return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join(""),
  );

  return JSON.parse(jsonPayload);
}

export function randomString(length) {
  let result = "";
  const characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  const charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}

export function result(obj, path, defaultResult, ...params) {
  const func = _.get(obj, path);
  if (typeof func === "function") {
    return func(...params);
  } else {
    return defaultResult;
  }
}

export function getCookie(name) {
  var match = document.cookie.match(new RegExp("(^| )" + name + "=([^;]+)"));
  if (match) return match[2];
}

export const getSubdomain = () => {
  const list = window.location.host.split(".");
  return list.length === 3 ? list[0] : null;
};

export const COUNTRY_CODE_SEPARATOR = "BKJBEFLKJEFWLLNF";
export const slicePhone = (phoneText = "") => {
  if (_.isEmpty(phoneText)) {
    return "-";
  } else {
    const [countryCode, phone] = phoneText.split(COUNTRY_CODE_SEPARATOR);
    return `+${countryCode} ${phone.slice(0, 3)}-${phone.slice(
      3,
      7,
    )}-${phone.slice(7, 11)}`;
  }
};
export const getFormErrorMsg = (errors, fieldName) => {
  const errorObj = _.get(errors, fieldName);
  return errorObj ? errorObj.message : null;
};

function fallbackCopyTextToClipboard(text) {
  var textArea = document.createElement("textarea");
  textArea.value = text;

  // Avoid scrolling to bottom
  textArea.style.top = "0";
  textArea.style.left = "0";
  textArea.style.position = "fixed";

  document.body.appendChild(textArea);
  textArea.focus();
  textArea.select();

  try {
    var successful = document.execCommand("copy");
    var msg = successful ? "successful" : "unsuccessful";
    console.log("Fallback: Copying text command was " + msg);
    return successful;
  } catch (err) {
    console.error("Fallback: Oops, unable to copy", err);
    return false;
  } finally {
    document.body.removeChild(textArea);
  }
}

export function copyTextToClipboard(text) {
  if (!navigator.clipboard) {
    return fallbackCopyTextToClipboard(text);
  }
  navigator.clipboard.writeText(text).then(
    function () {
      console.log("Async: Copying to clipboard was successful!");
    },
    function (err) {
      console.error("Async: Could not copy text: ", err);
    },
  );
}

export function CustomerStatusToKor(status) {
  if (status === "SIGNUP_COMPLETED") {
    return "가입 완료됨";
  }

  if (status === "SIGNUP_REQUESTED") {
    return "가입 요청됨";
  }

  if (status === "SIGNUP_REJECTED") {
    return "가입 거절됨";
  }
}

export function DepositStatusToKor(status) {
  if (status === "SUCCESS") {
    return "입금 완료됨";
  }

  if (status === "CANCELED") {
    return "입금 취소됨";
  }
}

export function DistributorStatusToKor(status) {
  if (status === "SIGNUP_COMPLETED") {
    return "가입 완료함";
  } else if (status === "SIGNUP_REQUESTED") {
    return "가입 요청함";
  } else if (status === "UPDATE_REQUESTED") {
    return "수정 요청함";
  }
}

export function WithdrawalStatusToKor(status) {
  if (status === "REQUESTED") {
    return "출금 요청됨";
  }

  if (status === "PROCESSED") {
    return "출금 처리됨";
  }
}
export function fRefundAccount(virtualAccount) {
  return `${_.get(virtualAccount, "refund_bank.name")} ${_.get(
    virtualAccount,
    "refund_account_number",
  )} ${_.get(virtualAccount, "refund_account_owner")}`;
}

export function fBankAccount(virtualAccount) {
  return `${_.get(virtualAccount, "bank.name")} ${_.get(
    virtualAccount,
    "account_number",
  )} ${_.get(virtualAccount, "account_owner")}`;
}

export function daumSigungu(address) {
  return address?.sigungu || address?.sido.slice(0, 4);
}

export function getByteLength(str) {
  return new TextEncoder().encode(str).length;
}

export function fPhone(phone: string) {
  return `${phone.slice(0, 3)}-${phone.slice(3, 7)}-${phone.slice(7)}`;
}

export function maxByteLengthYupTestObj(msg, length) {
  return {
    name: "byteLength",
    message: msg,
    test: value => getByteLength(value) <= length,
  };
}
