import { NativeSelect, styled } from "@mui/material";
import * as _ from "lodash";
import { countryCodes } from "utils/const";
export const Select = styled(NativeSelect)`
  @media (max-width: 600px) {
    width: 100%;
    margin-bottom: 8px;
  }

  @media (min-width: 600px) {
    margin-right: 8px;
  }

  &::placeholder {
    color: #6d6d6d;
    font-size: 14px;
  }
`;

export function CountrySelect({ onSelect, ...rest }) {
  return (
    <Select
      onChange={e => {
        const countryTable = _.keyBy(countryCodes, "internationalNumber");
        onSelect(countryTable[e.target.value as string], e);
      }}
      {...rest}
      // variant="filled"
    >
      {countryCodes.map(c => (
        <option
          key={c.imsmsCode}
          value={c.internationalNumber}
          style={{ height: 40 }}
        >
          {c.imsmsCode}
        </option>
      ))}
    </Select>
  );
}
