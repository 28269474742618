import { Grid, Stack, styled, TextField, Typography } from "@mui/material";
import { Label } from "app/component/styles";
import { getByteLength } from "utils/helper";
import { useTheme } from "@mui/material/styles";
import { useTranslation } from "react-i18next";

export const TransparentField = styled(TextField)`
  margin-top: 16px;
  margin-left: 8px;
  & label.Mui-focused {
    color: transparent;
  }
  & .MuiOutlinedInput-root {
    &.Mui-focused fieldset {
      border-color: transparent;
    }
  }
  & fieldset.MuiOutlinedInput-notchedOutline {
    border-color: transparent;
  }
`;

export default function MessageContent({ form, template }: any) {
  const { t } = useTranslation("MessagePage");

  const theme = useTheme();
  const isBright = theme.palette.mode === "light";
  return (
    <Stack sx={{ pt: 2, display: { xs: "none", md: "inline" } }}>
      <Grid
        container
        sx={{ bgcolor: isBright ? "#F1F4FF" : "#333333", borderRadius: "6px" }}
      >
        <Grid item xs={6}>
          <TransparentField
            InputProps={{ style: { paddingBottom: 32, flex: 1 } }}
            multiline={true}
            minRows={15}
            fullWidth={true}
            inputProps={form.register("template")}
            onChange={e => {
              if (getByteLength(template) <= 2000) {
                form.setValue("template", e.target.value);
              }
            }}
            placeholder={t(
              `Please enter messages. “Send various messages using templates and variables.”`,
            )}
          />
          <Stack
            direction="row"
            justifyContent="flex-end"
            sx={{ borderTop: "1px solid #E6E6E6", ml: 2, mt: 1 }}
          >
            <Typography sx={{ py: 2 }}>{`${getByteLength(
              template,
            )}/2000 byte`}</Typography>
          </Stack>
        </Grid>
        <Grid item xs={6} sx={{ px: 3, mt: 3 }}>
          <Stack sx={{ height: "100%", pb: "20px" }}>
            <Label>{t("Preview")}</Label>
            <Stack
              sx={{
                flex: 1,
                bgcolor: isBright ? "#fff" : "#1e1e1e",
                border: "2px solid black",
                borderRadius: "6px",
                mt: 2,
                p: 3,
                maxHeight: 400,
                overflowY: "scroll",
                "::-webkit-scrollbar": {
                  display: "none",
                },
              }}
            >
              <Typography sx={{ mt: 1.5 }}>
                {template ? (
                  template.split("\n").map((line, i) => (
                    <>
                      {i > 0 && <br />}
                      {line}
                    </>
                  ))
                ) : (
                  <></>
                )}
              </Typography>
            </Stack>
          </Stack>
        </Grid>
      </Grid>
    </Stack>
  );
}
