import { Stack } from "@mui/material";
import { styled } from "@mui/system";
import _ from "lodash";
import React, { useCallback } from "react";
import { useDeepCompareEffect } from "react-use";
import { ModalText, useModalToggle } from "../component/Modal";
import { OkModal } from "../component/OkModal";

export const makeYupValidator = (schema: any) => async (data: any) => {
  try {
    const values = await schema.validate(data, {
      abortEarly: false,
    });

    return {
      values,
      errors: {},
    };
  } catch (errors: any) {
    return {
      values: {},
      errors: errors.inner?.reduce(
        (allErrors: any, currentError: any) => ({
          ...allErrors,
          [currentError.path]: {
            type: currentError.type ?? "validation",
            message: currentError.message,
          },
        }),
        {},
      ),
    };
  }
};

export const useYupValidationResolver = (validationSchema: any) => {
  const resolver = useCallback(
    (data: any) => makeYupValidator(validationSchema)(data),
    [validationSchema],
  );

  return resolver;
};

const Text = styled("p")`
  color: #ff4842;
  font-size: 0.75rem;
`;

export function ErrorMsg({ form, name, ...rest }: any) {
  const fieldName = typeof name === "string" ? name : _.first(name);
  const errMsg = _.get(form, `formState.errors.${fieldName}.message`);
  return !!errMsg ? <Text {...rest}>{errMsg}</Text> : <></>;
}

const MsgWrap = styled(Stack)`
  min-width: 360px;
  max-height: 180px;
  overflow-y: scroll;

  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  &::-webkit-scrollbar {
    display: none;
  }
`;
export function FormErrorModal({
  form,
  exclusionFields = [] as string[],
  title = "",
  onClose = _.noop,
}: any) {
  const fields = _.difference(_.keys(form.formState.errors), exclusionFields);
  const errMsgs = fields
    .map(it => {
      const err = _.get(form.formState.errors, `${it}`);
      return _.get(err, "message");
    })
    .filter(_.identity);

  const { open, close, on } = useModalToggle();

  useDeepCompareEffect(() => {
    if (!_.isEmpty(errMsgs)) {
      open();
    } else {
      close();
    }
  }, [errMsgs, open, close]);

  return (
    <OkModal
      title={title}
      on={on}
      onClose={() => {
        onClose({ fields, errMsgs });
        form.clearErrors();
        close();
      }}
    >
      <MsgWrap>
        {errMsgs.map((it, index) => (
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="center"
            sx={{ mb: 2 }}
            key={index}
          >
            <img
              src={require("../media/File.png")}
              alt="체크표시"
              style={{
                width: 14,
                height: 14,
              }}
            />
            <ModalText sx={{ ml: 2 }}>{it}</ModalText>
          </Stack>
        ))}
      </MsgWrap>
    </OkModal>
  );
}
