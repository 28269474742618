import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import axios from "axios";
import _ from "lodash";
import { call, put, select } from "redux-saga/effects";
import { logger } from "utils/helper";
import { REFRESH_ACCESS_TOKEN, selectAccessToken } from "./authSlice";
export const initialUser = {
  id: null,
  phone: "",
  // user loading이 완료될때까지 페이지 로드를 기다림
  loading: true,
};

const slice = createSlice({
  name: "user",
  initialState: initialUser,
  reducers: {
    changeUser(state, action: PayloadAction<any>) {
      _.keys(action.payload).forEach(key => {
        _.set(state, key, action.payload[key]);
      });
    },
    initUser(state) {
      _.keys(state).forEach(key => {
        state[key] = null;
      });
    },
  },
});

export const { actions: userActions, reducer: userReducer } = slice;

const selectDomain = state => state.user || initialUser;

export const userSelector = createSelector(
  [selectDomain],
  userState => userState,
);

function getGetUserMe(accessToken) {
  return axios({
    method: "GET",
    url: "/users/me/",
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
}

export const REFRESH_USER_SAGA = "REFRESH_USER_SAGA";
export function* refreshUserSaga() {
  try {
    yield put(userActions.changeUser({ loading: true }));
    const accessToken = yield select(selectAccessToken);

    const token = accessToken;
    if (!token) {
      logger.log("NO TOKEN");
      return;
    }

    yield getUser(token);
  } catch (err) {
    if (_.get(err, "response.data.error") === "JWT_NOT_VERIFIED") {
      yield put({ type: REFRESH_ACCESS_TOKEN });
    } else {
      const action = userActions.changeUser(initialUser);
      logger.log("USER REFRESH INIT", err, action);
      yield put(action);
    }
  } finally {
    const action = userActions.changeUser({ loading: false });
    yield put(action);
  }
}

export function* getUser(token: any) {
  const userRes = yield call(getGetUserMe, token);
  const userData = _.get(userRes, "data.data");
  const userAction = userActions.changeUser(userData);
  yield put(userAction);
}
