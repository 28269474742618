import { Close } from "@mui/icons-material";
import {
  Box,
  Card,
  CardContent,
  Grid,
  Stack,
  Typography,
  Button,
} from "@mui/material";
import { OutlinedButton } from "app/component/styles";
import { CenterModal, useModalToggle } from "../../component/Modal";
import {
  CloseButton,
  ModalContent,
  ModalDivider,
  ModalHead,
} from "./ModalStyle";
import { useTheme } from "@mui/material/styles";
import { useTranslation } from "react-i18next";

export default function TemplateButton({ form }: any) {
  const { on, open, close } = useModalToggle();
  const theme = useTheme();
  const isBright = theme.palette.mode === "light";

  const { t } = useTranslation("MessagePage");

  const templates = [
    {
      id: 1,
      text: `welcome. Dear #name#.
      If you come to our OOO store today, we will offer you a beer for 100 yen until 8pm.`,
    },
    {
      id: 2,
      text: `"congratulations!
    “If you receive this message, come to the OOO store today and you will receive a free plate of yakitori.”`,
    },
    {
      id: 3,
      text: `Thank you for visiting us today.
      Did you like the food?
      We will continue to do our best to provide delicious and high quality food.`,
    },
    {
      id: 4,
      text: `本日はご来店いただきありがとうございます。
      料理はお口に合いましたか？
      これからもおいしくて質の高い料理を提供できるように最善を尽くします。`,
    },
  ];
  return (
    <>
      {isBright ? (
        <OutlinedButton variant="outlined" onClick={open} sx={{ flex: 1 }}>
          {t("message templates")}
        </OutlinedButton>
      ) : (
        <Button
          variant="contained"
          color="secondary"
          onClick={open}
          sx={{ flex: 1, boxShadow: "none", fontSize: 14, height: 50 }}
        >
          {t("message templates")}
        </Button>
      )}
      <CenterModal open={on} onClose={close}>
        <ModalContent>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            sx={{ py: 2 }}
          >
            <span style={{ width: 22 }} />
            <ModalHead>テンプレート</ModalHead>
            <CloseButton size="small" onClick={close}>
              <Close sx={{ color: "#ffffff", width: 18, height: 18 }} />
            </CloseButton>
          </Stack>
          <ModalDivider />
          <Box sx={{ p: 2 }}>
            <Grid container={true} spacing={2}>
              {templates.map(tpl => (
                <Grid item={true} key={tpl.id} xs={6}>
                  <Card
                    onClick={() => {
                      form.setValue("template", tpl.text);
                      close();
                    }}
                    sx={{ height: "100%" }}
                  >
                    <CardContent>
                      <Typography>{tpl.text}</Typography>
                    </CardContent>
                  </Card>
                </Grid>
              ))}
            </Grid>
          </Box>
        </ModalContent>
      </CenterModal>
    </>
  );
}
