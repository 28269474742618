import { Helmet } from "react-helmet-async";
import { BrowserRouter } from "react-router-dom";
import ThemeConfig from "theme";
import GlobalStyles from "theme/globalStyles";
import Router from "./routes";

export function App() {
  return (
    <BrowserRouter>
      <Helmet titleTemplate="" defaultTitle="">
        <meta name="keywords" content="" />
      </Helmet>
      <ThemeConfig>
        <GlobalStyles />
        <Router />
      </ThemeConfig>
    </BrowserRouter>
  );
}
