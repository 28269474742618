import { Box, Divider, Stack, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import {
  ApiFailModal,
  useSuccessRedirect,
} from "app/component/ApiResultHandler";
import { LanguageSelect } from "app/component/LanguageSelect";
import { AccountButton, PrimaryButton, TextInput } from "app/component/styles";
import { useAxios } from "app/hooks/useAuthAxios";
import { useYupValidationResolver } from "app/hooks/useYupValidationResolver";
import { CHANGE_TOKENS } from "app/store/authSlice";
import _ from "lodash";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";

function LoginPage() {
  const theme = useTheme();
  const navigate = useNavigate();
  const [postTokenResult, postToken] = useAxios({
    method: "POST",
    url: "/login/",
  });

  const { t } = useTranslation("LoginPage");

  const resolver = useYupValidationResolver(
    Yup.object().shape({
      email: Yup.string()
        .required(t("Please enter your ID"))
        .max(100, t("Maximum 100 digits")),
      password: Yup.string()
        .required(t("Please enter your password"))
        .max(200, t("Maximum 200 digits")),
    }),
  );
  const form = useForm<any>({
    mode: "onTouched",
    resolver,
    defaultValues: {
      email: "",
      password: "",
    },
    shouldUnregister: false,
  });

  const handleSubmit = form.handleSubmit(values => {
    const payload = {
      email: values.email,
      password: values.password,
    };
    postToken({ data: payload });
  });

  const dispatch = useDispatch();
  useSuccessRedirect(postTokenResult, "/message", {
    onSuccess: (postTokenResult: any) => {
      dispatch({
        type: CHANGE_TOKENS,
        payload: {
          accessToken: _.get(postTokenResult, "data.data.accessToken"),
          refreshToken: _.get(postTokenResult, "data.data.refreshToken"),
        },
      });
    },
    successCode: 201,
  });
  const { errors, touchedFields: touched } = form.formState;

  return (
    <>
      <Stack
        sx={{
          maxWidth: 842,
          margin: "auto",
          p: 5,
          background: "#ffffff",
          height: "100%",
          overflowY: "scroll",
          "::-webkit-scrollbar": {
            display: "none",
          },
          position: "relative",
        }}
      >
        {/* <Popup /> */}
        <Stack direction="row" justifyContent="flex-end">
          <LanguageSelect />
        </Stack>

        <Stack sx={{ pt: "96px", pb: 5 }}>
          {theme.palette.mode === "light" ? (
            <img
              src={require("app/media/JapanLogo.png")}
              alt="logo img"
              style={{
                width: 112,
                height: 74,
              }}
            />
          ) : (
            <img
              src={require("app/media/darkModeLogo.png")}
              alt="logo img"
              style={{
                width: 112,
                height: 74,
              }}
            />
          )}
          <Typography sx={{ fontSize: 28, fontWeight: 700, pt: 3 }}>
            Welcome
          </Typography>
          <Typography sx={{ fontSize: 18, fontWeight: 400 }}>
            {t("Please enter your")} <br />
            {t("ID and password")}
          </Typography>
        </Stack>
        <Stack spacing={1.5} sx={{ mb: 2 }}>
          <TextInput
            placeholder={t("Please enter ID")}
            inputProps={form.register("email")}
            error={Boolean(touched.email && errors.email)}
            helperText={_.get(errors, "email.message", "") as string}
          ></TextInput>
          <TextInput
            placeholder={t("Please enter password")}
            inputProps={form.register("password")}
            error={Boolean(touched.password && errors.password)}
            helperText={_.get(errors, "password.message", "") as string}
            type={"password"}
            onKeyDown={e => {
              if (e.key === "Enter") {
                handleSubmit();
              }
            }}
          ></TextInput>
        </Stack>
        <Divider />
        <Stack spacing={1.5} sx={{ mt: 2 }}>
          <PrimaryButton
            variant="contained"
            sx={{ mb: 0 }}
            onClick={handleSubmit}
          >
            {t("LOGIN")}
          </PrimaryButton>
          <ApiFailModal
            apiResult={postTokenResult}
            ErrorCodeTable={{
              INVALID_USER: {
                title: "Login failed",
                content: "Please check your user ID or PW.",
              },
              USER_NOT_APPROVED: {
                title: "Waiting to sign up.",
                content: (
                  <>
                    {t("It will take some time to post your demo account.")}
                    <br />
                    {t("Issuance takes 1 to 3 days.")}
                  </>
                ),
              },
            }}
          />
          <AccountButton variant="text" onClick={() => navigate("/account/")}>
            {t("Signup for newsletter")}
          </AccountButton>
        </Stack>
      </Stack>
    </>
  );
}

export default LoginPage;

export function Popup() {
  const [open, setOpen] = useState(true);
  const naviagte = useNavigate();
  return open ? (
    <Box
      sx={{
        position: "absolute",
        right: "20px",
        top: "40px",
        width: "240px",
        zIndex: 1000,
      }}
      onClick={() => naviagte("/account")}
    >
      <img src={require("app/media/layer_popup.png")} alt="pop up" />
      <button
        style={{
          backgroundColor: "transparent",
          border: "0",
          position: "absolute",
          width: 25,
          height: 25,
          right: 14,
          top: 16,
        }}
        onClick={e => {
          e.stopPropagation();
          setOpen(false);
        }}
      ></button>
    </Box>
  ) : (
    <></>
  );
}
