import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import { makeUseAxios } from "axios-hooks";
import produce from "immer";
import _ from "lodash";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { usePreviousDistinct, useUpdateEffect } from "react-use";
import useDeepCompareEffect from "react-use/lib/useDeepCompareEffect";
import { getCookie, logger } from "utils/helper";
import {
  REFRESH_ACCESS_TOKEN,
  selectAccessToken,
  selectRefreshToken,
} from "../store/authSlice";
import { useDeepCompareCallback } from "./useDeepCompare";

axios.defaults.headers.common["X-CSRFToken"] = getCookie("csrftoken");

export function useAuthAxios(axiosOps, hookOps?): any {
  const accessToken = useSelector(selectAccessToken);
  const refreshToken = useSelector(selectRefreshToken);

  logger.debug("HOOK OPS", hookOps);
  const hookOptions: {
    manual: boolean;
  } = produce(hookOps, draft => {
    return _.defaults(draft, { manual: true });
  }) as any;

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<AxiosResponse | undefined>();
  const [response, setResponse] = useState<AxiosResponse | undefined>();

  const [axiosOpsCache, setAxiosOpsCache] = useState<AxiosRequestConfig | null>(
    null,
  );
  const [refreshing, setRefreshing] = useState(false);

  const dispatch = useDispatch();
  const execute = useDeepCompareCallback(
    async function (op = {}) {
      if (refreshing) {
        // alert('1. REFRESHING');
        return;
      }

      setLoading(true);
      let res;
      try {
        logger.debug("AXIOS OPS", axiosOps);
        const defaultOp: any = produce(axiosOps, draft => {
          return getDefaultOp(draft, accessToken);
        });

        const isEvent = op instanceof Event;
        const safeOp = isEvent ? {} : op;
        logger.debug("SAFE OPS", safeOp);
        const axiosOp: any = produce(safeOp, draft => {
          return _.defaultsDeep(draft, defaultOp);
        });

        res = await axios(axiosOp)
          .then(res => res)
          .catch(err => err);
        if (res.status >= 200 && res.status < 300) {
          setResponse(res);
          setError(undefined);
        } else if (
          _.get(res, "response.data.error") === "JWT_NOT_VERIFIED" &&
          !!refreshToken
        ) {
          logger.log("AUTH DISPATCHING");
          setRefreshing(true);
          setAxiosOpsCache(axiosOp);
          dispatch({ type: REFRESH_ACCESS_TOKEN });
          setError(res);
          setResponse(undefined);
        } else {
          logger.log("AXIOS ERR RES", res);
          setError(res);
          setResponse(undefined);
        }
      } catch (err) {
        logger.error("AXIOS ERR", err);
      } finally {
        setLoading(false);
      }
    },
    [axiosOps, dispatch],
  );

  const isManual = hookOptions.manual;
  useDeepCompareEffect(() => {
    if (!isManual) {
      // alert('5. NON MANUAL');
      execute();
    }
  }, [isManual, execute]);

  const prevToken = usePreviousDistinct(accessToken);
  useUpdateEffect(() => {
    const isTokenRefreshed =
      prevToken && accessToken && prevToken !== accessToken;

    if (isTokenRefreshed) {
      // alert(`6. REFRESHED ${JSON.stringify(axiosOpsCache || '', null, 2)}`);
      setRefreshing(false);
    }
  }, [prevToken, accessToken]);

  useUpdateEffect(() => {
    if (!refreshing && !!axiosOpsCache) {
      logger.debug("Axios ops cache", axiosOpsCache);
      const axiosOp: any = produce(axiosOpsCache, draft => {
        _.set(draft || {}, "headers.Authorization", `Bearer ${accessToken}`);
      });
      // alert(`7. EXECUTE AGAIN ${JSON.stringify(axiosOp, null, 2)}`);
      execute(axiosOp);
      setAxiosOpsCache(null);
    }
  }, [refreshing, axiosOpsCache]);

  function init() {
    setResponse(undefined);
    setError(undefined);
    setLoading(false);
  }

  return [
    { loading, error, response, data: _.get(response, "data") },
    execute,
    init,
  ];
}

function getDefaultOp(userOption, accessToken) {
  return _.defaultsDeep(
    userOption,
    !!accessToken
      ? {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      : null,
  );
}

export const useAxios = makeUseAxios({
  axios,
  defaultOptions: { manual: true },
});

export function useAuthDownload(fileName, axiosOps, hookOps?) {
  const [result, method, init] = useAuthAxios(
    { method: "GET", ...axiosOps, responseType: "blob" },
    hookOps,
  );

  const { loading, response } = result;
  const successData = _.get(result, "response.data");
  const contentType = _.get(response, "headers.content-type");
  useEffect(() => {
    if (successData && !loading) {
      const blob = new Blob([successData], { type: contentType });
      const url = window.URL.createObjectURL(blob);

      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", fileName);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }, [successData, contentType, loading, fileName]);
  return [method, result, init];
}
