import { Close } from "@mui/icons-material";
import {
  Button,
  Divider,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import { ApiFailModal } from "app/component/ApiResultHandler";
import { OutlinedButton, PrimaryButton } from "app/component/styles";
import { useAuthAxios, useAuthDownload } from "app/hooks/useAuthAxios";
import {
  differenceInDays,
  differenceInMonths,
  endOfDay,
  startOfDay,
  subDays,
  subMonths,
  subWeeks,
} from "date-fns";
import _ from "lodash";
import { useState } from "react";
import { formatDate } from "utils/helper";
import { CenterModal, useModalToggle } from "../../component/Modal";
import { CloseButton, ModalDivider, ModalHead } from "../Message/ModalStyle";
import { AddressContent } from "../address/ListStyle";
import { useTranslation } from "react-i18next";
import { t } from "i18next";

enum MsgStatus {
  RESERVED = "RESERVED",
  SUCCESS = "SUCCESS",
  FAIL = "FAIL",
}

function getMsgStatus(msg) {
  if (_.get(msg, "requestStatus") === "RESERVED") {
    return MsgStatus.RESERVED;
  }

  if (_.get(msg, "status.messageDelivered")) {
    return MsgStatus.SUCCESS;
  }

  if (
    _.get(msg, "requestStatus") === "REQUEST_FAIL" ||
    (!_.get(msg, "status.messageDelivered") &&
      _.get(msg, "status.messageFinished"))
  ) {
    return MsgStatus.FAIL;
  }

  return "-";
}

export default function HistoryButton() {
  const { t } = useTranslation("MessagePage");

  const { on, open, close } = useModalToggle();
  const [from, setFrom] = useState(subWeeks(new Date(), 1));
  const [to, setTo] = useState(new Date());
  const theme = useTheme();
  const isBright = theme.palette.mode === "light";
  const [getResult, fetch] = useAuthAxios(
    {
      method: "GET",
      url: "/messages",
      params: { from: startOfDay(from), to: endOfDay(to), pageSize: 1000 },
    },
    { manual: true },
  );
  const msgs = _.get(getResult, "response.data.data");

  const HistoryList = [
    { id: "date", label: t("send date") },
    { id: "phone", label: t("Receive number") },
    { id: "content", label: t("attach") },
    { id: "state", label: t("result") },
  ];

  const StatusLabelTable = {
    [MsgStatus.SUCCESS]: t("Transfer completed"),
    [MsgStatus.FAIL]: t("Transfer failed"),
    [MsgStatus.RESERVED]: t("Transfer resereved."),
  };
  const StatusColorTable = {
    [MsgStatus.SUCCESS]: "#5818DF",
    [MsgStatus.FAIL]: "#B12310",
    [MsgStatus.RESERVED]: "#000",
  };

  return (
    <>
      {isBright ? (
        <OutlinedButton sx={{ py: 1.5 }} onClick={open}>
          {t("Sent message history")}
        </OutlinedButton>
      ) : (
        <Button
          variant="contained"
          color="secondary"
          sx={{ fontSize: 14, height: 50, flex: 1, boxShadow: "none", py: 1.5 }}
          onClick={open}
        >
          {t("Sent message history")}
        </Button>
      )}
      <CenterModal open={on} onClose={close}>
        <AddressContent>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            sx={{ py: 2 }}
          >
            <span style={{ width: 22 }} />
            <ModalHead sx={{ color: "#111111", fontSize: 18 }}>
              {t("Send history")}
            </ModalHead>
            <CloseButton size="small" onClick={close}>
              <Close sx={{ color: "#111111", width: 18, height: 18 }} />
            </CloseButton>
          </Stack>
          <ModalDivider />
          <Stack direction="column" alignItems="center" spacing={1.5}>
            <Typography sx={{ color: "#6D6D6D", fontSize: 12, mt: 2 }}>
              {t("Only messages sent in the last 30 days will be displayed.")}
            </Typography>
            <Stack
              direction="row"
              flexWrap="wrap"
              sx={{ width: "100%", px: 2 }}
              spacing={2}
              justifyContent="center"
            >
              <Stack direction="row" alignItems="center" spacing={1}>
                <StartEndDatePicker date={from} setDate={setFrom} />
                <Typography sx={{ color: "#6D6D6D" }}> - </Typography>
                <StartEndDatePicker date={to} setDate={setTo} />
              </Stack>
              <Stack direction="row">
                <UsageRadioGroup
                  from={from}
                  to={to}
                  setFrom={setFrom}
                  setTo={setTo}
                />
              </Stack>
            </Stack>
          </Stack>
          <Stack
            sx={{
              color: "#111111",
              maxHeight: "80%",
              width: "100%",
              overflowY: "scroll",
              "::-webkit-scrollbar": {
                display: "none",
              },
              px: 2,
            }}
          >
            <Stack sx={{ color: "#111111" }}>
              <Table>
                <TableHead>
                  <TableRow>
                    {HistoryList.map((it, index) => (
                      <TableCell align="center" sx={{ borderWidth: 0 }}>
                        <Typography
                          key={it.id}
                          sx={{ fontWeight: 700, fontSize: 13 }}
                        >
                          {it.label}
                        </Typography>
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {_.map(msgs, it => (
                    <TableRow key={it.id}>
                      {[
                        {
                          text: (
                            <>
                              {formatDate(it.createdAt)}
                              <br />
                              {formatDate(it.createdAt, "hh:MM:ss")}
                            </>
                          ),
                        },
                        { text: it.receiver },
                        {
                          text:
                            it.type === "txt"
                              ? `${it.template.substring(0, 20)}${
                                  it.template.length > 20 ? "..." : ""
                                }`
                              : "image type",
                        },
                        {
                          text: StatusLabelTable[getMsgStatus(it)] || "-",
                          style: {
                            color: StatusColorTable[getMsgStatus(it)],
                            fontWeight: 700,
                          },
                        },
                      ].map((v, index) => (
                        <TableCell sx={{ borderWidth: 0 }}>
                          <Typography
                            key={index}
                            align="center"
                            sx={{ fontSize: 13, color: "#6D6D6D" }}
                            style={v.style}
                          >
                            {v.text}
                          </Typography>
                        </TableCell>
                      ))}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Stack>
          </Stack>
          <Divider sx={{ mx: 2 }} />
          <Stack
            direction="row"
            justifyContent="space-between"
            sx={{ color: "#111111", p: 2 }}
            alignItems="center"
          >
            <span></span>
            <Typography sx={{ fontSize: 14 }}>
              {t("Total ")}
              {_.get(getResult, "response.data.count", 0)}
              {t(" items")}
            </Typography>
          </Stack>
          <Stack direction="row" sx={{ mx: 2 }} spacing={1}>
            <PrimaryButton
              sx={{ bgcolor: "#5818DF", flex: 1 }}
              onClick={() => fetch()}
            >
              {t("close")}
            </PrimaryButton>
            <ExcelDownloadButton from={from} to={to} />
          </Stack>
        </AddressContent>
      </CenterModal>
    </>
  );
}

function StartEndDatePicker({ date, setDate, ...rest }: any) {
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <MobileDatePicker
        value={date}
        inputFormat="yyyy.MM.dd"
        onChange={(newDate: any) => {
          setDate(newDate);
        }}
        renderInput={(params: any) => <TextField {...params} size="small" />}
        {...rest}
      />
    </LocalizationProvider>
  );
}

export function UsageRadioGroup({ from, to, setFrom, setTo }: any) {
  const LabelStyle = {
    "& .MuiFormControlLabel-label": {
      fontSize: "14px",
      color: "#111111",
    },
  };
  return (
    <FormControl>
      <RadioGroup>
        <Stack direction="row">
          <FormControlLabel
            value={true}
            control={
              <Radio
                color="primary"
                checked={differenceInDays(new Date(), from) === 6}
                onClick={() => {
                  setFrom(subDays(new Date(), 6));
                  setTo(new Date());
                }}
              />
            }
            label={t("1day")}
            sx={LabelStyle}
          />
          <FormControlLabel
            value={false}
            control={<Radio color="primary" />}
            label={t("15day")}
            sx={LabelStyle}
            checked={differenceInDays(new Date(), from) === 14}
            onClick={() => {
              setFrom(subDays(new Date(), 14));
              setTo(new Date());
            }}
          />
          <FormControlLabel
            value={false}
            control={<Radio color="primary" />}
            label={t("1month")}
            sx={{
              "& .MuiFormControlLabel-label": {
                fontSize: "14px",
                color: "#111111",
              },
            }}
            checked={differenceInMonths(new Date(), from) === 1}
            onClick={() => {
              setFrom(subMonths(new Date(), 1));
              setTo(new Date());
            }}
          />
        </Stack>
      </RadioGroup>
    </FormControl>
  );
}

function ExcelDownloadButton({ from, to }) {
  const [download, downloadResult] = useAuthDownload("messages", {
    method: "GET",
    url: "/messages/excel",
    params: { from: startOfDay(from), to: endOfDay(to), pageSize: 1000 },
  });

  return (
    <>
      <OutlinedButton onClick={() => download()}>
        {t("export to excel")}
      </OutlinedButton>
      <ApiFailModal apiResult={downloadResult} />
    </>
  );
}
