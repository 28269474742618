export const countryCodes = [
  {
    countryNameInKorean: "가나",
    countryNameInEnglish: "Ghana",
    internationalNumber: 233,
    twoLettersCountryCode: "GH",
    threeLettersCountryCode: "GHA",
    imsmsCode: "233 (Ghana)",
  },
  {
    countryNameInKorean: "가봉",
    countryNameInEnglish: "Gabon",
    internationalNumber: 241,
    twoLettersCountryCode: "GA",
    threeLettersCountryCode: "GAB",
    imsmsCode: "241 (Gabon)",
  },
  {
    countryNameInKorean: "가이아나",
    countryNameInEnglish: "Guyana",
    internationalNumber: 592,
    twoLettersCountryCode: "GY",
    threeLettersCountryCode: "GUY",
    imsmsCode: "592 (Guyana)",
  },
  {
    countryNameInKorean: "감비아",
    countryNameInEnglish: "Gambia",
    internationalNumber: 220,
    twoLettersCountryCode: "GM",
    threeLettersCountryCode: "GMB",
    imsmsCode: "220 (Gambia)",
  },
  {
    countryNameInKorean: "괌",
    countryNameInEnglish: "Guam",
    internationalNumber: 1671,
    twoLettersCountryCode: "GU",
    threeLettersCountryCode: "GUM",
    imsmsCode: "1671 (Guam)",
  },
  {
    countryNameInKorean: "과들루프",
    countryNameInEnglish: "Guadeloupe",
    internationalNumber: 590,
    twoLettersCountryCode: "GP",
    threeLettersCountryCode: "GLP",
    imsmsCode: "590 (Guadeloupe)",
  },
  {
    countryNameInKorean: "과테말라",
    countryNameInEnglish: "Guatemala",
    internationalNumber: 502,
    twoLettersCountryCode: "GT",
    threeLettersCountryCode: "GTM",
    imsmsCode: "502 (Guatemala)",
  },
  {
    countryNameInKorean: "그레나다",
    countryNameInEnglish: "Grenada",
    internationalNumber: 1473,
    twoLettersCountryCode: "GD",
    threeLettersCountryCode: "GRD",
    imsmsCode: "1473 (Grenada)",
    Column11: " ",
  },
  {
    countryNameInKorean: "그리스",
    countryNameInEnglish: "Greece",
    internationalNumber: 30,
    twoLettersCountryCode: "GR",
    threeLettersCountryCode: "GRC",
    imsmsCode: "30 (Greece)",
  },
  {
    countryNameInKorean: "그린란드",
    countryNameInEnglish: "Greenland",
    internationalNumber: 299,
    twoLettersCountryCode: "GL",
    threeLettersCountryCode: "GRL",
    imsmsCode: "299 (Greenland)",
  },
  {
    countryNameInKorean: "기니",
    countryNameInEnglish: "Guinea",
    internationalNumber: 224,
    twoLettersCountryCode: "GN",
    threeLettersCountryCode: "GIN",
    imsmsCode: "224 (Guinea)",
  },
  {
    countryNameInKorean: "기니비사우",
    countryNameInEnglish: "Guinea-Bissau",
    internationalNumber: 245,
    twoLettersCountryCode: "GW",
    threeLettersCountryCode: "GNB",
    imsmsCode: "245 (Guinea-Bissau)",
  },
  {
    countryNameInKorean: "나미비아",
    countryNameInEnglish: "Namibia",
    internationalNumber: 264,
    twoLettersCountryCode: "NA",
    threeLettersCountryCode: "NAM",
    imsmsCode: "264 (Namibia)",
  },
  {
    countryNameInKorean: "나우루",
    countryNameInEnglish: "Nauru",
    internationalNumber: 674,
    twoLettersCountryCode: "NR",
    threeLettersCountryCode: "NRU",
    imsmsCode: "674 (Nauru)",
  },
  {
    countryNameInKorean: "나이지리아",
    countryNameInEnglish: "Nigeria",
    internationalNumber: 234,
    twoLettersCountryCode: "NG",
    threeLettersCountryCode: "NGA",
    imsmsCode: "234 (Nigeria)",
  },
  {
    countryNameInKorean: "남수단",
    countryNameInEnglish: "South Sudan",
    internationalNumber: 211,
    twoLettersCountryCode: "SS",
    threeLettersCountryCode: "SSD",
    imsmsCode: "211 (South Sudan)",
  },
  {
    countryNameInKorean: "남아프리카 공화국",
    countryNameInEnglish: "South Africa",
    internationalNumber: 27,
    twoLettersCountryCode: "ZA",
    threeLettersCountryCode: "ZAF",
    imsmsCode: "27 (South Africa)",
  },
  {
    countryNameInKorean: "네덜란드",
    countryNameInEnglish: "the Netherlands",
    internationalNumber: 31,
    twoLettersCountryCode: "NL",
    threeLettersCountryCode: "NLD",
    imsmsCode: "31 (the Netherlands)",
  },
  {
    countryNameInKorean: "네팔",
    countryNameInEnglish: "Nepal",
    internationalNumber: 977,
    twoLettersCountryCode: "NP",
    threeLettersCountryCode: "NPL",
    imsmsCode: "977 (Nepal)",
  },
  {
    countryNameInKorean: "노르웨이",
    countryNameInEnglish: "Norway",
    internationalNumber: 47,
    twoLettersCountryCode: "NO",
    threeLettersCountryCode: "NOR",
    imsmsCode: "47 (Norway)",
  },
  {
    countryNameInKorean: "누벨칼레도니",
    countryNameInEnglish: "New Caledonia",
    internationalNumber: 687,
    twoLettersCountryCode: "NC",
    threeLettersCountryCode: "NCL",
    imsmsCode: "687 (New Caledonia)",
  },
  {
    countryNameInKorean: "뉴질랜드",
    countryNameInEnglish: "New Zealand",
    internationalNumber: 64,
    twoLettersCountryCode: "NZ",
    threeLettersCountryCode: "NZL",
    imsmsCode: "64 (New Zealand)",
  },
  {
    countryNameInKorean: "니우에",
    countryNameInEnglish: "Niue",
    internationalNumber: 683,
    twoLettersCountryCode: "NU",
    threeLettersCountryCode: "NIU",
    imsmsCode: "683 (Niue)",
  },
  {
    countryNameInKorean: "니제르",
    countryNameInEnglish: "Niger",
    internationalNumber: 227,
    twoLettersCountryCode: "NE",
    threeLettersCountryCode: "NER",
    imsmsCode: "227 (Niger)",
  },
  {
    countryNameInKorean: "니카라과",
    countryNameInEnglish: "Nicaragua",
    internationalNumber: 505,
    twoLettersCountryCode: "NI",
    threeLettersCountryCode: "NIC",
    imsmsCode: "505 (Nicaragua)",
  },
  {
    countryNameInKorean: "대한민국",
    countryNameInEnglish: "Republic of Korea",
    internationalNumber: 82,
    twoLettersCountryCode: "KR",
    threeLettersCountryCode: "KOR",
    imsmsCode: "82 (Republic of Korea)",
  },
  {
    countryNameInKorean: "덴마크",
    countryNameInEnglish: "Denmark",
    internationalNumber: 45,
    twoLettersCountryCode: "DK",
    threeLettersCountryCode: "DNK",
    imsmsCode: "45 (Denmark)",
  },
  {
    countryNameInKorean: "도미니카 공화국",
    countryNameInEnglish: "Dominican R.",
    internationalNumber: 1809,
    twoLettersCountryCode: "DO",
    threeLettersCountryCode: "DOM",
    imsmsCode: "1809 (Dominican R.)",
  },
  {
    countryNameInKorean: "도미니카 연방",
    countryNameInEnglish: "Dominica",
    internationalNumber: 1767,
    twoLettersCountryCode: "DM",
    threeLettersCountryCode: "DMA",
    imsmsCode: "1767 (Dominica)",
  },
  {
    countryNameInKorean: "독일",
    countryNameInEnglish: "Germany",
    internationalNumber: 49,
    twoLettersCountryCode: "DE",
    threeLettersCountryCode: "DEU",
    imsmsCode: "49 (Germany)",
  },
  {
    countryNameInKorean: "동티모르",
    countryNameInEnglish: "East Timor",
    internationalNumber: 670,
    twoLettersCountryCode: "TL",
    threeLettersCountryCode: "TLS",
    imsmsCode: "670 (East Timor)",
  },
  {
    countryNameInKorean: "라오스",
    countryNameInEnglish: "Laos",
    internationalNumber: 856,
    twoLettersCountryCode: "LA",
    threeLettersCountryCode: "LAO",
    imsmsCode: "856 (Laos)",
  },
  {
    countryNameInKorean: "라이베리아",
    countryNameInEnglish: "Liberia",
    internationalNumber: 231,
    twoLettersCountryCode: "LR",
    threeLettersCountryCode: "LBR",
    imsmsCode: "231 (Liberia)",
  },
  {
    countryNameInKorean: "라트비아",
    countryNameInEnglish: "Latvia",
    internationalNumber: 371,
    twoLettersCountryCode: "LV",
    threeLettersCountryCode: "LVA",
    imsmsCode: "371 (Latvia)",
  },
  {
    countryNameInKorean: "러시아",
    countryNameInEnglish: "Russia",
    internationalNumber: 7,
    twoLettersCountryCode: "RU",
    threeLettersCountryCode: "RUS",
    imsmsCode: "7 (Russia)",
  },
  {
    countryNameInKorean: "레바논",
    countryNameInEnglish: "Lebanon",
    internationalNumber: 961,
    twoLettersCountryCode: "LB",
    threeLettersCountryCode: "LBN",
    imsmsCode: "961 (Lebanon)",
  },
  {
    countryNameInKorean: "레소토",
    countryNameInEnglish: "Lesotho",
    internationalNumber: 266,
    twoLettersCountryCode: "LS",
    threeLettersCountryCode: "LSO",
    imsmsCode: "266 (Lesotho)",
  },
  {
    countryNameInKorean: "루마니아",
    countryNameInEnglish: "Romania",
    internationalNumber: 40,
    twoLettersCountryCode: "RO",
    threeLettersCountryCode: "ROU",
    imsmsCode: "40 (Romania)",
  },
  {
    countryNameInKorean: "룩셈부르크",
    countryNameInEnglish: "Luxembourg",
    internationalNumber: 352,
    twoLettersCountryCode: "LU",
    threeLettersCountryCode: "LUX",
    imsmsCode: "352 (Luxembourg)",
  },
  {
    countryNameInKorean: "르완다",
    countryNameInEnglish: "Rwanda",
    internationalNumber: 250,
    twoLettersCountryCode: "RW",
    threeLettersCountryCode: "RWA",
    imsmsCode: "250 (Rwanda)",
  },
  {
    countryNameInKorean: "리비아",
    countryNameInEnglish: "Libya",
    internationalNumber: 218,
    twoLettersCountryCode: "LY",
    threeLettersCountryCode: "LBY",
    imsmsCode: "218 (Libya)",
  },
  {
    countryNameInKorean: "리투아니아",
    countryNameInEnglish: "Lithuania",
    internationalNumber: 370,
    twoLettersCountryCode: "LT",
    threeLettersCountryCode: "LTU",
    imsmsCode: "370 (Lithuania)",
  },
  {
    countryNameInKorean: "리히텐슈타인",
    countryNameInEnglish: "Liechtenstein",
    internationalNumber: 423,
    twoLettersCountryCode: "LI",
    threeLettersCountryCode: "LIE",
    imsmsCode: "423 (Liechtenstein)",
  },
  {
    countryNameInKorean: "마다가스카르",
    countryNameInEnglish: "Madagascar",
    internationalNumber: 261,
    twoLettersCountryCode: "MG",
    threeLettersCountryCode: "MDG",
    imsmsCode: "261 (Madagascar)",
  },
  {
    countryNameInKorean: "마르티니크",
    countryNameInEnglish: "Martinique",
    internationalNumber: 596,
    twoLettersCountryCode: "MQ",
    threeLettersCountryCode: "MTQ",
    imsmsCode: "596 (Martinique)",
  },
  {
    countryNameInKorean: "마셜 제도",
    countryNameInEnglish: "Marshall Islands",
    internationalNumber: 692,
    twoLettersCountryCode: "MH",
    threeLettersCountryCode: "MHL",
    imsmsCode: "692 (Marshall Islands)",
  },
  {
    countryNameInKorean: "마요트",
    countryNameInEnglish: "Mayotte",
    internationalNumber: 269,
    twoLettersCountryCode: "YT",
    threeLettersCountryCode: "MYT",
    imsmsCode: "269 (Mayotte)",
  },
  {
    countryNameInKorean: "마카오",
    countryNameInEnglish: "Macao",
    internationalNumber: 853,
    twoLettersCountryCode: "MO",
    threeLettersCountryCode: "MAC",
    imsmsCode: "853 (Macao)",
  },
  {
    countryNameInKorean: "말라위",
    countryNameInEnglish: "Malawi",
    internationalNumber: 265,
    twoLettersCountryCode: "MW",
    threeLettersCountryCode: "MWI",
    imsmsCode: "265 (Malawi)",
  },
  {
    countryNameInKorean: "말레이시아",
    countryNameInEnglish: "Malaysia",
    internationalNumber: 60,
    twoLettersCountryCode: "MY",
    threeLettersCountryCode: "MYS",
    imsmsCode: "60 (Malaysia)",
  },
  {
    countryNameInKorean: "말리",
    countryNameInEnglish: "Mali",
    internationalNumber: 223,
    twoLettersCountryCode: "ML",
    threeLettersCountryCode: "MLI",
    imsmsCode: "223 (Mali)",
  },
  {
    countryNameInKorean: "멕시코",
    countryNameInEnglish: "Mexico",
    internationalNumber: 52,
    twoLettersCountryCode: "MX",
    threeLettersCountryCode: "MEX",
    imsmsCode: "52 (Mexico)",
  },
  {
    countryNameInKorean: "모나코",
    countryNameInEnglish: "Monaco",
    internationalNumber: 377,
    twoLettersCountryCode: "MC",
    threeLettersCountryCode: "MCO",
    imsmsCode: "377 (Monaco)",
  },
  {
    countryNameInKorean: "모로코",
    countryNameInEnglish: "Morocco",
    internationalNumber: 212,
    twoLettersCountryCode: "MA",
    threeLettersCountryCode: "MAR",
    imsmsCode: "212 (Morocco)",
  },
  {
    countryNameInKorean: "모리셔스",
    countryNameInEnglish: "Mauritius",
    internationalNumber: 230,
    twoLettersCountryCode: "MU",
    threeLettersCountryCode: "MUS",
    imsmsCode: "230 (Mauritius)",
  },
  {
    countryNameInKorean: "모리타니",
    countryNameInEnglish: "Mauritania",
    internationalNumber: 222,
    twoLettersCountryCode: "MR",
    threeLettersCountryCode: "MRT",
    imsmsCode: "222 (Mauritania)",
  },
  {
    countryNameInKorean: "모잠비크",
    countryNameInEnglish: "Mozambique",
    internationalNumber: 258,
    twoLettersCountryCode: "MZ",
    threeLettersCountryCode: "MOZ",
    imsmsCode: "258 (Mozambique)",
  },
  {
    countryNameInKorean: "몬테네그로",
    countryNameInEnglish: "Montenegro",
    internationalNumber: 382,
    twoLettersCountryCode: "ME",
    threeLettersCountryCode: "MNE",
    imsmsCode: "382 (Montenegro)",
  },
  {
    countryNameInKorean: "몬트세랫",
    countryNameInEnglish: "Montserrat",
    internationalNumber: 1664,
    twoLettersCountryCode: "MS",
    threeLettersCountryCode: "MSR",
    imsmsCode: "1664 (Montserrat)",
  },
  {
    countryNameInKorean: "몰도바",
    countryNameInEnglish: "Maldova",
    internationalNumber: 373,
    twoLettersCountryCode: "MD",
    threeLettersCountryCode: "MDA",
    imsmsCode: "373 (Maldova)",
  },
  {
    countryNameInKorean: "몰디브",
    countryNameInEnglish: "Maldives",
    internationalNumber: 960,
    twoLettersCountryCode: "MV",
    threeLettersCountryCode: "MDV",
    imsmsCode: "960 (Maldives)",
  },
  {
    countryNameInKorean: "몰타",
    countryNameInEnglish: "Malta",
    internationalNumber: 356,
    twoLettersCountryCode: "MT",
    threeLettersCountryCode: "MLT",
    imsmsCode: "356 (Malta)",
  },
  {
    countryNameInKorean: "몽골",
    countryNameInEnglish: "Mongolia",
    internationalNumber: 976,
    twoLettersCountryCode: "MN",
    threeLettersCountryCode: "MNG",
    imsmsCode: "976 (Mongolia)",
  },
  {
    countryNameInKorean: "미국",
    countryNameInEnglish: "United States of America",
    internationalNumber: 1,
    twoLettersCountryCode: "US",
    threeLettersCountryCode: "USA",
    imsmsCode: "1 (United States of America)",
  },
  {
    countryNameInKorean: "미얀마(버마",
    countryNameInEnglish: "Myanmar",
    internationalNumber: 95,
    twoLettersCountryCode: "MM",
    threeLettersCountryCode: "MMR",
    imsmsCode: "95 (Myanmar)",
  },
  {
    countryNameInKorean: "미크로네시아 연방",
    countryNameInEnglish: "Micronesia",
    internationalNumber: 691,
    twoLettersCountryCode: "FM",
    threeLettersCountryCode: "FSM",
    imsmsCode: "691 (Micronesia)",
  },
  {
    countryNameInKorean: "바누아투",
    countryNameInEnglish: "Vanuatu",
    internationalNumber: 678,
    twoLettersCountryCode: "VU",
    threeLettersCountryCode: "VUT",
    imsmsCode: "678 (Vanuatu)",
  },
  {
    countryNameInKorean: "바레인",
    countryNameInEnglish: "Bahrain",
    internationalNumber: 973,
    twoLettersCountryCode: "BH",
    threeLettersCountryCode: "BHR",
    imsmsCode: "973 (Bahrain)",
  },
  {
    countryNameInKorean: "바베이도스",
    countryNameInEnglish: "Barbados",
    internationalNumber: 1246,
    twoLettersCountryCode: "BB",
    threeLettersCountryCode: "BRB",
    imsmsCode: "1246 (Barbados)",
  },
  {
    countryNameInKorean: "바티칸 시국",
    countryNameInEnglish: "Vatican",
    internationalNumber: 379,
    twoLettersCountryCode: "VA",
    threeLettersCountryCode: "VAT",
    imsmsCode: "379 (Vatican)",
  },
  {
    countryNameInKorean: "바하마",
    countryNameInEnglish: "Bahamas",
    internationalNumber: 1242,
    twoLettersCountryCode: "BS",
    threeLettersCountryCode: "BHS",
    imsmsCode: "1242 (Bahamas)",
  },
  {
    countryNameInKorean: "방글라데시",
    countryNameInEnglish: "Bangladesh",
    internationalNumber: 880,
    twoLettersCountryCode: "BD",
    threeLettersCountryCode: "BGD",
    imsmsCode: "880 (Bangladesh)",
  },
  {
    countryNameInKorean: "버뮤다",
    countryNameInEnglish: "Bermuda",
    internationalNumber: 1441,
    twoLettersCountryCode: "BM",
    threeLettersCountryCode: "BMU",
    imsmsCode: "1441 (Bermuda)",
  },
  {
    countryNameInKorean: "베냉",
    countryNameInEnglish: "Benin",
    internationalNumber: 229,
    twoLettersCountryCode: "BJ",
    threeLettersCountryCode: "BEN",
    imsmsCode: "229 (Benin)",
  },
  {
    countryNameInKorean: "베네수엘라",
    countryNameInEnglish: "Venezuela",
    internationalNumber: 58,
    twoLettersCountryCode: "VE",
    threeLettersCountryCode: "VEN",
    imsmsCode: "58 (Venezuela)",
  },
  {
    countryNameInKorean: "베트남",
    countryNameInEnglish: "Vietnam",
    internationalNumber: 84,
    twoLettersCountryCode: "VN",
    threeLettersCountryCode: "VNM",
    imsmsCode: "84 (Vietnam)",
  },
  {
    countryNameInKorean: "벨기에",
    countryNameInEnglish: "Belgium",
    internationalNumber: 32,
    twoLettersCountryCode: "BE",
    threeLettersCountryCode: "BEL",
    imsmsCode: "32 (Belgium)",
  },
  {
    countryNameInKorean: "벨라루스",
    countryNameInEnglish: "Belarus",
    internationalNumber: 375,
    twoLettersCountryCode: "BY",
    threeLettersCountryCode: "BLR",
    imsmsCode: "375 (Belarus)",
  },
  {
    countryNameInKorean: "벨리즈",
    countryNameInEnglish: "Belize",
    internationalNumber: 501,
    twoLettersCountryCode: "BZ",
    threeLettersCountryCode: "BLZ",
    imsmsCode: "501 (Belize)",
  },
  {
    countryNameInKorean: "보스니아 헤르체고비나",
    countryNameInEnglish: "Bosnia and Herzegovina",
    internationalNumber: 387,
    twoLettersCountryCode: "BA",
    threeLettersCountryCode: "BIH",
    imsmsCode: "387 (Bosnia and Herzegovina)",
  },
  {
    countryNameInKorean: "보츠와나",
    countryNameInEnglish: "Botswana",
    internationalNumber: 267,
    twoLettersCountryCode: "BW",
    threeLettersCountryCode: "BWA",
    imsmsCode: "267 (Botswana)",
  },
  {
    countryNameInKorean: "볼리비아",
    countryNameInEnglish: "Bolivia",
    internationalNumber: 591,
    twoLettersCountryCode: "BO",
    threeLettersCountryCode: "BOL",
    imsmsCode: "591 (Bolivia)",
  },
  {
    countryNameInKorean: "부룬디",
    countryNameInEnglish: "Burundi",
    internationalNumber: 257,
    twoLettersCountryCode: "BI",
    threeLettersCountryCode: "BDI",
    imsmsCode: "257 (Burundi)",
  },
  {
    countryNameInKorean: "부르키나파소",
    countryNameInEnglish: "Burkina Faso",
    internationalNumber: 226,
    twoLettersCountryCode: "BF",
    threeLettersCountryCode: "BFA",
    imsmsCode: "226 (Burkina Faso)",
  },
  {
    countryNameInKorean: "부탄",
    countryNameInEnglish: "Bhutan",
    internationalNumber: 975,
    twoLettersCountryCode: "BT",
    threeLettersCountryCode: "BTN",
    imsmsCode: "975 (Bhutan)",
  },
  {
    countryNameInKorean: "북마케도니아",
    countryNameInEnglish: "Macedonia",
    internationalNumber: 389,
    twoLettersCountryCode: "MK",
    threeLettersCountryCode: "MKD",
    imsmsCode: "389 (Macedonia)",
  },
  {
    countryNameInKorean: "불가리아",
    countryNameInEnglish: "Bulgaria",
    internationalNumber: 359,
    twoLettersCountryCode: "BG",
    threeLettersCountryCode: "BGR",
    imsmsCode: "359 (Bulgaria)",
  },
  {
    countryNameInKorean: "브라질",
    countryNameInEnglish: "Brazil",
    internationalNumber: 55,
    twoLettersCountryCode: "BR",
    threeLettersCountryCode: "BRA",
    imsmsCode: "55 (Brazil)",
  },
  {
    countryNameInKorean: "브루나이",
    countryNameInEnglish: "Brunei",
    internationalNumber: 673,
    twoLettersCountryCode: "BN",
    threeLettersCountryCode: "BRN",
    imsmsCode: "673 (Brunei)",
  },
  {
    countryNameInKorean: "사모아",
    countryNameInEnglish: "Samoa",
    internationalNumber: 685,
    twoLettersCountryCode: "WS",
    threeLettersCountryCode: "WSM",
    imsmsCode: "685 (Samoa)",
  },
  {
    countryNameInKorean: "사우디아라비아",
    countryNameInEnglish: "Saudi Arabia",
    internationalNumber: 966,
    twoLettersCountryCode: "SA",
    threeLettersCountryCode: "SAU",
    imsmsCode: "966 (Saudi Arabia)",
  },
  {
    countryNameInKorean: "산마리노",
    countryNameInEnglish: "San Marino",
    internationalNumber: 378,
    twoLettersCountryCode: "SM",
    threeLettersCountryCode: "SMR",
    imsmsCode: "378 (San Marino)",
  },
  {
    countryNameInKorean: "생피에르 미클롱",
    countryNameInEnglish: "Saint Pierre and Miquelon",
    internationalNumber: 508,
    twoLettersCountryCode: "PM",
    threeLettersCountryCode: "SPM",
    imsmsCode: "508 (Saint Pierre and Miquelon)",
  },
  {
    countryNameInKorean: "서사하라",
    countryNameInEnglish: "Western Sahara",
    internationalNumber: 212,
    twoLettersCountryCode: "EH",
    threeLettersCountryCode: "ESH",
    imsmsCode: "212 (Western Sahara)",
  },
  {
    countryNameInKorean: "세네갈",
    countryNameInEnglish: "Senegal",
    internationalNumber: 221,
    twoLettersCountryCode: "SN",
    threeLettersCountryCode: "SEN",
    imsmsCode: "221 (Senegal)",
  },
  {
    countryNameInKorean: "세르비아",
    countryNameInEnglish: "Serbia",
    internationalNumber: 381,
    twoLettersCountryCode: "RS",
    threeLettersCountryCode: "SRB",
    imsmsCode: "381 (Serbia)",
  },
  {
    countryNameInKorean: "세이셸",
    countryNameInEnglish: "Seychelles",
    internationalNumber: 248,
    twoLettersCountryCode: "SC",
    threeLettersCountryCode: "SYC",
    imsmsCode: "248 (Seychelles)",
  },
  {
    countryNameInKorean: "세인트루시아",
    countryNameInEnglish: "Saint Lucia",
    internationalNumber: 1758,
    twoLettersCountryCode: "LC",
    threeLettersCountryCode: "LCA",
    imsmsCode: "1758 (Saint Lucia)",
  },
  {
    countryNameInKorean: "세인트헬레나",
    countryNameInEnglish: "Saint Helena",
    internationalNumber: 290,
    twoLettersCountryCode: "SH",
    threeLettersCountryCode: "SHN",
    imsmsCode: "290 (Saint Helena)",
  },
  {
    countryNameInKorean: "소말리아",
    countryNameInEnglish: "Somalia",
    internationalNumber: 252,
    twoLettersCountryCode: "SO",
    threeLettersCountryCode: "SOM",
    imsmsCode: "252 (Somalia)",
  },
  {
    countryNameInKorean: "솔로몬 제도",
    countryNameInEnglish: "Solomon Islands",
    internationalNumber: 677,
    twoLettersCountryCode: "SB",
    threeLettersCountryCode: "SLB",
    imsmsCode: "677 (Solomon Islands)",
  },
  {
    countryNameInKorean: "수단",
    countryNameInEnglish: "Sudan",
    internationalNumber: 249,
    twoLettersCountryCode: "SD",
    threeLettersCountryCode: "SDN",
    imsmsCode: "249 (Sudan)",
  },
  {
    countryNameInKorean: "수리남",
    countryNameInEnglish: "Suriname",
    internationalNumber: 597,
    twoLettersCountryCode: "SR",
    threeLettersCountryCode: "SUR",
    imsmsCode: "597 (Suriname)",
  },
  {
    countryNameInKorean: "스리랑카",
    countryNameInEnglish: "Sri Lanka",
    internationalNumber: 94,
    twoLettersCountryCode: "LK",
    threeLettersCountryCode: "LKA",
    imsmsCode: "94 (Sri Lanka)",
  },
  {
    countryNameInKorean: "스웨덴",
    countryNameInEnglish: "Sweden",
    internationalNumber: 46,
    twoLettersCountryCode: "SE",
    threeLettersCountryCode: "SWE",
    imsmsCode: "46 (Sweden)",
  },
  {
    countryNameInKorean: "스위스",
    countryNameInEnglish: "Switzerland",
    internationalNumber: 41,
    twoLettersCountryCode: "CH",
    threeLettersCountryCode: "CHE",
    imsmsCode: "41 (Switzerland)",
  },
  {
    countryNameInKorean: "스페인",
    countryNameInEnglish: "Spain",
    internationalNumber: 34,
    twoLettersCountryCode: "ES",
    threeLettersCountryCode: "ESP",
    imsmsCode: "34 (Spain)",
  },
  {
    countryNameInKorean: "슬로바키아",
    countryNameInEnglish: "Slovakia",
    internationalNumber: 421,
    twoLettersCountryCode: "SK",
    threeLettersCountryCode: "SVK",
    imsmsCode: "421 (Slovakia)",
  },
  {
    countryNameInKorean: "슬로베니아",
    countryNameInEnglish: "Slovenia",
    internationalNumber: 386,
    twoLettersCountryCode: "SI",
    threeLettersCountryCode: "SVN",
    imsmsCode: "386 (Slovenia)",
  },
  {
    countryNameInKorean: "시리아",
    countryNameInEnglish: "Syria",
    internationalNumber: 963,
    twoLettersCountryCode: "SY",
    threeLettersCountryCode: "SYR",
    imsmsCode: "963 (Syria)",
  },
  {
    countryNameInKorean: "시에라리온",
    countryNameInEnglish: "Sierra Leone",
    internationalNumber: 232,
    twoLettersCountryCode: "SL",
    threeLettersCountryCode: "SLE",
    imsmsCode: "232 (Sierra Leone)",
  },
  {
    countryNameInKorean: "신트마르턴",
    countryNameInEnglish: "Sint Maarten",
    internationalNumber: 1721,
    twoLettersCountryCode: "SX",
    threeLettersCountryCode: "SXM",
    imsmsCode: "1721 (Sint Maarten)",
  },
  {
    countryNameInKorean: "싱가포르",
    countryNameInEnglish: "Singapore",
    internationalNumber: 65,
    twoLettersCountryCode: "SG",
    threeLettersCountryCode: "SGP",
    imsmsCode: "65 (Singapore)",
  },
  {
    countryNameInKorean: "아랍에미리트",
    countryNameInEnglish: "United Arab Emirates",
    internationalNumber: 971,
    twoLettersCountryCode: "AE",
    threeLettersCountryCode: "ARE",
    imsmsCode: "971 (United Arab Emirates)",
  },
  {
    countryNameInKorean: "아루바",
    countryNameInEnglish: "Aruba",
    internationalNumber: 297,
    twoLettersCountryCode: "AW",
    threeLettersCountryCode: "ABW",
    imsmsCode: "297 (Aruba)",
  },
  {
    countryNameInKorean: "아르메니아",
    countryNameInEnglish: "Armenia",
    internationalNumber: 374,
    twoLettersCountryCode: "AM",
    threeLettersCountryCode: "ARM",
    imsmsCode: "374 (Armenia)",
  },
  {
    countryNameInKorean: "아르헨티나",
    countryNameInEnglish: "Argentina",
    internationalNumber: 54,
    twoLettersCountryCode: "AR",
    threeLettersCountryCode: "ARG",
    imsmsCode: "54 (Argentina)",
  },
  {
    countryNameInKorean: "아메리칸사모아",
    countryNameInEnglish: "American Samoa",
    internationalNumber: 1684,
    twoLettersCountryCode: "AS",
    threeLettersCountryCode: "ASM",
    imsmsCode: "1684 (American Samoa)",
  },
  {
    countryNameInKorean: "아이슬란드",
    countryNameInEnglish: "Iceland",
    internationalNumber: 354,
    twoLettersCountryCode: "IS",
    threeLettersCountryCode: "ISL",
    imsmsCode: "354 (Iceland)",
  },
  {
    countryNameInKorean: "아이티",
    countryNameInEnglish: "Haiti",
    internationalNumber: 509,
    twoLettersCountryCode: "HT",
    threeLettersCountryCode: "HTI",
    imsmsCode: "509 (Haiti)",
  },
  {
    countryNameInKorean: "아일랜드",
    countryNameInEnglish: "Ireland",
    internationalNumber: 353,
    twoLettersCountryCode: "IE",
    threeLettersCountryCode: "IRL",
    imsmsCode: "353 (Ireland)",
  },
  {
    countryNameInKorean: "아제르바이잔",
    countryNameInEnglish: "Azerbaijan",
    internationalNumber: 994,
    twoLettersCountryCode: "AZ",
    threeLettersCountryCode: "AZE",
    imsmsCode: "994 (Azerbaijan)",
  },
  {
    countryNameInKorean: "아프가니스탄",
    countryNameInEnglish: "Afghanistan",
    internationalNumber: 93,
    twoLettersCountryCode: "AF",
    threeLettersCountryCode: "AFG",
    imsmsCode: "93 (Afghanistan)",
  },
  {
    countryNameInKorean: "안도라",
    countryNameInEnglish: "Andorra",
    internationalNumber: 376,
    twoLettersCountryCode: "AD",
    threeLettersCountryCode: "AND",
    imsmsCode: "376 (Andorra)",
  },
  {
    countryNameInKorean: "알바니아",
    countryNameInEnglish: "Albania",
    internationalNumber: 355,
    twoLettersCountryCode: "AL",
    threeLettersCountryCode: "ALB",
    imsmsCode: "355 (Albania)",
  },
  {
    countryNameInKorean: "알제리",
    countryNameInEnglish: "Algeria",
    internationalNumber: 213,
    twoLettersCountryCode: "DZ",
    threeLettersCountryCode: "DZA",
    imsmsCode: "213 (Algeria)",
  },
  {
    countryNameInKorean: "앙골라",
    countryNameInEnglish: "Angola",
    internationalNumber: 244,
    twoLettersCountryCode: "AO",
    threeLettersCountryCode: "AGO",
    imsmsCode: "244 (Angola)",
  },
  {
    countryNameInKorean: "앤티가 바부다",
    countryNameInEnglish: "Antigua and Barbuda",
    internationalNumber: 1268,
    twoLettersCountryCode: "AG",
    threeLettersCountryCode: "ATG",
    imsmsCode: "1268 (Antigua and Barbuda)",
  },
  {
    countryNameInKorean: "앵귈라",
    countryNameInEnglish: "Anguilla",
    internationalNumber: 1264,
    twoLettersCountryCode: "AI",
    threeLettersCountryCode: "AIA",
    imsmsCode: "1264 (Anguilla)",
  },
  {
    countryNameInKorean: "에리트레아",
    countryNameInEnglish: "Eritrea",
    internationalNumber: 291,
    twoLettersCountryCode: "ER",
    threeLettersCountryCode: "ERI",
    imsmsCode: "291 (Eritrea)",
  },
  {
    countryNameInKorean: "에스와티니",
    countryNameInEnglish: "Eswatini",
    internationalNumber: 268,
    twoLettersCountryCode: "SZ",
    threeLettersCountryCode: "SWZ",
    imsmsCode: "268 (Eswatini)",
  },
  {
    countryNameInKorean: "에스토니아",
    countryNameInEnglish: "Estonia",
    internationalNumber: 372,
    twoLettersCountryCode: "EE",
    threeLettersCountryCode: "EST",
    imsmsCode: "372 (Estonia)",
  },
  {
    countryNameInKorean: "에콰도르",
    countryNameInEnglish: "Ecuador",
    internationalNumber: 593,
    twoLettersCountryCode: "EC",
    threeLettersCountryCode: "ECU",
    imsmsCode: "593 (Ecuador)",
  },
  {
    countryNameInKorean: "에티오피아",
    countryNameInEnglish: "Ethiopia",
    internationalNumber: 251,
    twoLettersCountryCode: "ET",
    threeLettersCountryCode: "ETH",
    imsmsCode: "251 (Ethiopia)",
  },
  {
    countryNameInKorean: "엘살바도르",
    countryNameInEnglish: "El Salvador",
    internationalNumber: 503,
    twoLettersCountryCode: "SV",
    threeLettersCountryCode: "SLV",
    imsmsCode: "503 (El Salvador)",
  },
  {
    countryNameInKorean: "영국",
    countryNameInEnglish: "United Kingdom",
    internationalNumber: 44,
    twoLettersCountryCode: "GB",
    threeLettersCountryCode: "GBR",
    imsmsCode: "44 (United Kingdom)",
  },
  {
    countryNameInKorean: "예멘",
    countryNameInEnglish: "Yemen",
    internationalNumber: 967,
    twoLettersCountryCode: "YE",
    threeLettersCountryCode: "YEM",
    imsmsCode: "967 (Yemen)",
  },
  {
    countryNameInKorean: "오만",
    countryNameInEnglish: "Oman",
    internationalNumber: 968,
    twoLettersCountryCode: "OM",
    threeLettersCountryCode: "OMN",
    imsmsCode: "968 (Oman)",
  },
  {
    countryNameInKorean: "오스트레일리아",
    countryNameInEnglish: "Australia",
    internationalNumber: 61,
    twoLettersCountryCode: "AU",
    threeLettersCountryCode: "AUS",
    imsmsCode: "61 (Australia)",
  },
  {
    countryNameInKorean: "오스트리아",
    countryNameInEnglish: "Austria",
    internationalNumber: 43,
    twoLettersCountryCode: "AT",
    threeLettersCountryCode: "AUT",
    imsmsCode: "43 (Austria)",
  },
  {
    countryNameInKorean: "온두라스",
    countryNameInEnglish: "Honduras",
    internationalNumber: 504,
    twoLettersCountryCode: "HN",
    threeLettersCountryCode: "HND",
    imsmsCode: "504 (Honduras)",
  },
  {
    countryNameInKorean: "요르단",
    countryNameInEnglish: "Jordan",
    internationalNumber: 962,
    twoLettersCountryCode: "JO",
    threeLettersCountryCode: "JOR",
    imsmsCode: "962 (Jordan)",
  },
  {
    countryNameInKorean: "우간다",
    countryNameInEnglish: "Uganda",
    internationalNumber: 256,
    twoLettersCountryCode: "UG",
    threeLettersCountryCode: "UGA",
    imsmsCode: "256 (Uganda)",
  },
  {
    countryNameInKorean: "우루과이",
    countryNameInEnglish: "Uruguay",
    internationalNumber: 598,
    twoLettersCountryCode: "UY",
    threeLettersCountryCode: "URY",
    imsmsCode: "598 (Uruguay)",
  },
  {
    countryNameInKorean: "우즈베키스탄",
    countryNameInEnglish: "Uzbekistan",
    internationalNumber: 998,
    twoLettersCountryCode: "UZ",
    threeLettersCountryCode: "UZB",
    imsmsCode: "998 (Uzbekistan)",
  },
  {
    countryNameInKorean: "우크라이나",
    countryNameInEnglish: "Ukraine",
    internationalNumber: 380,
    twoLettersCountryCode: "UA",
    threeLettersCountryCode: "UKR",
    imsmsCode: "380 (Ukraine)",
  },
  {
    countryNameInKorean: "이라크",
    countryNameInEnglish: "Iraq",
    internationalNumber: 964,
    twoLettersCountryCode: "IQ",
    threeLettersCountryCode: "IRQ",
    imsmsCode: "964 (Iraq)",
  },
  {
    countryNameInKorean: "이란",
    countryNameInEnglish: "Iran",
    internationalNumber: 98,
    twoLettersCountryCode: "IR",
    threeLettersCountryCode: "IRN",
    imsmsCode: "98 (Iran)",
  },
  {
    countryNameInKorean: "이스라엘",
    countryNameInEnglish: "Israel",
    internationalNumber: 972,
    twoLettersCountryCode: "IL",
    threeLettersCountryCode: "ISR",
    imsmsCode: "972 (Israel)",
  },
  {
    countryNameInKorean: "이집트",
    countryNameInEnglish: "Egypt",
    internationalNumber: 20,
    twoLettersCountryCode: "EG",
    threeLettersCountryCode: "EGY",
    imsmsCode: "20 (Egypt)",
  },
  {
    countryNameInKorean: "이탈리아",
    countryNameInEnglish: "Italy",
    internationalNumber: 39,
    twoLettersCountryCode: "IT",
    threeLettersCountryCode: "ITA",
    imsmsCode: "39 (Italy)",
  },
  {
    countryNameInKorean: "인도",
    countryNameInEnglish: "India",
    internationalNumber: 91,
    twoLettersCountryCode: "IN",
    threeLettersCountryCode: "IND",
    imsmsCode: "91 (India)",
  },
  {
    countryNameInKorean: "인도네시아",
    countryNameInEnglish: "Indonesia",
    internationalNumber: 62,
    twoLettersCountryCode: "ID",
    threeLettersCountryCode: "IDN",
    imsmsCode: "62 (Indonesia)",
  },
  {
    countryNameInKorean: "일본",
    countryNameInEnglish: "Japan",
    internationalNumber: 81,
    twoLettersCountryCode: "JP",
    threeLettersCountryCode: "JPN",
    imsmsCode: "81 (Japan)",
  },
  {
    countryNameInKorean: "자메이카",
    countryNameInEnglish: "Jamaica",
    internationalNumber: 1876,
    twoLettersCountryCode: "JM",
    threeLettersCountryCode: "JAM",
    imsmsCode: "1876 (Jamaica)",
  },
  {
    countryNameInKorean: "잠비아",
    countryNameInEnglish: "Zambia",
    internationalNumber: 260,
    twoLettersCountryCode: "ZM",
    threeLettersCountryCode: "ZMB",
    imsmsCode: "260 (Zambia)",
  },
  {
    countryNameInKorean: "적도 기니",
    countryNameInEnglish: "Equatorial Guinea",
    internationalNumber: 240,
    twoLettersCountryCode: "GQ",
    threeLettersCountryCode: "GNQ",
    imsmsCode: "240 (Equatorial Guinea)",
  },
  {
    countryNameInKorean: "북한",
    countryNameInEnglish: "DPR of Korea",
    internationalNumber: 850,
    twoLettersCountryCode: "KP",
    threeLettersCountryCode: "PRK",
    imsmsCode: "850 (DPR of Korea)",
  },
  {
    countryNameInKorean: "조지아",
    countryNameInEnglish: "Georgia",
    internationalNumber: 995,
    twoLettersCountryCode: "GE",
    threeLettersCountryCode: "GEO",
    imsmsCode: "995 (Georgia)",
  },
  {
    countryNameInKorean: "중앙아프리카 공화국",
    countryNameInEnglish: "Central Africa",
    internationalNumber: 236,
    twoLettersCountryCode: "CF",
    threeLettersCountryCode: "CAF",
    imsmsCode: "236 (Central Africa)",
  },
  {
    countryNameInKorean: "대만",
    countryNameInEnglish: "Taiwan",
    internationalNumber: 886,
    twoLettersCountryCode: "TW",
    threeLettersCountryCode: "TWN",
    imsmsCode: "886 (Taiwan)",
  },
  {
    countryNameInKorean: "중화인민공화국",
    countryNameInEnglish: "China",
    internationalNumber: 86,
    twoLettersCountryCode: "CN",
    threeLettersCountryCode: "CHN",
    imsmsCode: "86 (China)",
  },
  {
    countryNameInKorean: "지부티",
    countryNameInEnglish: "Djibouti",
    internationalNumber: 253,
    twoLettersCountryCode: "DJ",
    threeLettersCountryCode: "DJI",
    imsmsCode: "253 (Djibouti)",
  },
  {
    countryNameInKorean: "지브롤터",
    countryNameInEnglish: "Gibraltar",
    internationalNumber: 350,
    twoLettersCountryCode: "GI",
    threeLettersCountryCode: "GIB",
    imsmsCode: "350 (Gibraltar)",
  },
  {
    countryNameInKorean: "짐바브웨",
    countryNameInEnglish: "Zimbabwe",
    internationalNumber: 263,
    twoLettersCountryCode: "ZW",
    threeLettersCountryCode: "ZWE",
    imsmsCode: "263 (Zimbabwe)",
  },
  {
    countryNameInKorean: "차드",
    countryNameInEnglish: "Chad",
    internationalNumber: 235,
    twoLettersCountryCode: "TD",
    threeLettersCountryCode: "TCD",
    imsmsCode: "235 (Chad)",
  },
  {
    countryNameInKorean: "체코",
    countryNameInEnglish: "Czech",
    internationalNumber: 420,
    twoLettersCountryCode: "CZ",
    threeLettersCountryCode: "CZE",
    imsmsCode: "420 (Czech)",
  },
  {
    countryNameInKorean: "칠레",
    countryNameInEnglish: "Chile",
    internationalNumber: 56,
    twoLettersCountryCode: "CL",
    threeLettersCountryCode: "CHL",
    imsmsCode: "56 (Chile)",
  },
  {
    countryNameInKorean: "카메룬",
    countryNameInEnglish: "Cameroon",
    internationalNumber: 237,
    twoLettersCountryCode: "CM",
    threeLettersCountryCode: "CMR",
    imsmsCode: "237 (Cameroon)",
  },
  {
    countryNameInKorean: "카보베르데",
    countryNameInEnglish: "Cape Verde",
    internationalNumber: 238,
    twoLettersCountryCode: "CV",
    threeLettersCountryCode: "CPV",
    imsmsCode: "238 (Cape Verde)",
  },
  {
    countryNameInKorean: "카자흐스탄",
    countryNameInEnglish: "Kazakstan",
    internationalNumber: 7,
    twoLettersCountryCode: "KZ",
    threeLettersCountryCode: "KAZ",
    imsmsCode: "7 (Kazakstan)",
  },
  {
    countryNameInKorean: "카타르",
    countryNameInEnglish: "Qatar",
    internationalNumber: 974,
    twoLettersCountryCode: "QA",
    threeLettersCountryCode: "QAT",
    imsmsCode: "974 (Qatar)",
  },
  {
    countryNameInKorean: "캄보디아",
    countryNameInEnglish: "Cambodia",
    internationalNumber: 855,
    twoLettersCountryCode: "KH",
    threeLettersCountryCode: "KHM",
    imsmsCode: "855 (Cambodia)",
  },
  {
    countryNameInKorean: "캐나다",
    countryNameInEnglish: "Canada",
    internationalNumber: 1,
    twoLettersCountryCode: "CA",
    threeLettersCountryCode: "CAN",
    imsmsCode: "1 (Canada)",
  },
  {
    countryNameInKorean: "케냐",
    countryNameInEnglish: "Kenya",
    internationalNumber: 254,
    twoLettersCountryCode: "KE",
    threeLettersCountryCode: "KEN",
    imsmsCode: "254 (Kenya)",
  },
  {
    countryNameInKorean: "케이맨 제도",
    countryNameInEnglish: "Cayman Islands",
    internationalNumber: 1345,
    twoLettersCountryCode: "KY",
    threeLettersCountryCode: "CYM",
    imsmsCode: "1345 (Cayman Islands)",
  },
  {
    countryNameInKorean: "코모로",
    countryNameInEnglish: "the Comoros",
    internationalNumber: 269,
    twoLettersCountryCode: "KM",
    threeLettersCountryCode: "COM",
    imsmsCode: "269 (the Comoros)",
  },
  {
    countryNameInKorean: "코스타리카",
    countryNameInEnglish: "Costa Rica",
    internationalNumber: 506,
    twoLettersCountryCode: "CR",
    threeLettersCountryCode: "CRI",
    imsmsCode: "506 (Costa Rica)",
  },
  {
    countryNameInKorean: "코트디부아르",
    countryNameInEnglish: "Ivory Coast",
    internationalNumber: 225,
    twoLettersCountryCode: "CI",
    threeLettersCountryCode: "CIV",
    imsmsCode: "225 (Ivory Coast)",
  },
  {
    countryNameInKorean: "콜롬비아",
    countryNameInEnglish: "Colombia",
    internationalNumber: 57,
    twoLettersCountryCode: "CO",
    threeLettersCountryCode: "COL",
    imsmsCode: "57 (Colombia)",
  },
  {
    countryNameInKorean: "콩고 공화국",
    countryNameInEnglish: "Congo",
    internationalNumber: 242,
    twoLettersCountryCode: "CG",
    threeLettersCountryCode: "COG",
    imsmsCode: "242 (Congo)",
  },
  {
    countryNameInKorean: "콩고 민주 공화국",
    countryNameInEnglish: "DR Congo",
    internationalNumber: 243,
    twoLettersCountryCode: "CD",
    threeLettersCountryCode: "COD",
    imsmsCode: "243 (DR Congo)",
  },
  {
    countryNameInKorean: "쿠바",
    countryNameInEnglish: "Cuba",
    internationalNumber: 53,
    twoLettersCountryCode: "CU",
    threeLettersCountryCode: "CUB",
    imsmsCode: "53 (Cuba)",
  },
  {
    countryNameInKorean: "쿠웨이트",
    countryNameInEnglish: "Kuwait",
    internationalNumber: 965,
    twoLettersCountryCode: "KW",
    threeLettersCountryCode: "KWT",
    imsmsCode: "965 (Kuwait)",
  },
  {
    countryNameInKorean: "쿡 제도",
    countryNameInEnglish: "Cook Islands",
    internationalNumber: 682,
    twoLettersCountryCode: "CK",
    threeLettersCountryCode: "COK",
    imsmsCode: "682 (Cook Islands)",
  },
  {
    countryNameInKorean: "크로아티아",
    countryNameInEnglish: "Croatia",
    internationalNumber: 385,
    twoLettersCountryCode: "HR",
    threeLettersCountryCode: "HRV",
    imsmsCode: "385 (Croatia)",
  },
  {
    countryNameInKorean: "키르기스스탄",
    countryNameInEnglish: "Kyrgizstan",
    internationalNumber: 996,
    twoLettersCountryCode: "KG",
    threeLettersCountryCode: "KGZ",
    imsmsCode: "996 (Kyrgizstan)",
  },
  {
    countryNameInKorean: "키리바시",
    countryNameInEnglish: "Kiribati",
    internationalNumber: 686,
    twoLettersCountryCode: "KI",
    threeLettersCountryCode: "KIR",
    imsmsCode: "686 (Kiribati)",
  },
  {
    countryNameInKorean: "키프로스",
    countryNameInEnglish: "Cyprus",
    internationalNumber: 357,
    twoLettersCountryCode: "CY",
    threeLettersCountryCode: "CYP",
    imsmsCode: "357 (Cyprus)",
  },
  {
    countryNameInKorean: "타지키스탄",
    countryNameInEnglish: "Tajikistan",
    internationalNumber: 992,
    twoLettersCountryCode: "TJ",
    threeLettersCountryCode: "TJK",
    imsmsCode: "992 (Tajikistan)",
  },
  {
    countryNameInKorean: "탄자니아",
    countryNameInEnglish: "Tanzania",
    internationalNumber: 255,
    twoLettersCountryCode: "TZ",
    threeLettersCountryCode: "TZA",
    imsmsCode: "255 (Tanzania)",
  },
  {
    countryNameInKorean: "태국",
    countryNameInEnglish: "Thailand",
    internationalNumber: 66,
    twoLettersCountryCode: "TH",
    threeLettersCountryCode: "THA",
    imsmsCode: "66 (Thailand)",
  },
  {
    countryNameInKorean: "터키",
    countryNameInEnglish: "Turkey",
    internationalNumber: 90,
    twoLettersCountryCode: "TR",
    threeLettersCountryCode: "TUR",
    imsmsCode: "90 (Turkey)",
  },
  {
    countryNameInKorean: "토고",
    countryNameInEnglish: "Togo",
    internationalNumber: 228,
    twoLettersCountryCode: "TG",
    threeLettersCountryCode: "TGO",
    imsmsCode: "228 (Togo)",
  },
  {
    countryNameInKorean: "토켈라우",
    countryNameInEnglish: "Tokelau",
    internationalNumber: 690,
    twoLettersCountryCode: "TK",
    threeLettersCountryCode: "TKL",
    imsmsCode: "690 (Tokelau)",
  },
  {
    countryNameInKorean: "통가",
    countryNameInEnglish: "Tonga",
    internationalNumber: 676,
    twoLettersCountryCode: "TO",
    threeLettersCountryCode: "TON",
    imsmsCode: "676 (Tonga)",
  },
  {
    countryNameInKorean: "투르크메니스탄",
    countryNameInEnglish: "Turkmenistan",
    internationalNumber: 993,
    twoLettersCountryCode: "TM",
    threeLettersCountryCode: "TKM",
    imsmsCode: "993 (Turkmenistan)",
  },
  {
    countryNameInKorean: "투발루",
    countryNameInEnglish: "Tuvalu",
    internationalNumber: 688,
    twoLettersCountryCode: "TV",
    threeLettersCountryCode: "TUV",
    imsmsCode: "688 (Tuvalu)",
  },
  {
    countryNameInKorean: "튀니지",
    countryNameInEnglish: "Tunisia",
    internationalNumber: 216,
    twoLettersCountryCode: "TN",
    threeLettersCountryCode: "TUN",
    imsmsCode: "216 (Tunisia)",
  },
  {
    countryNameInKorean: "트리니다드 토바고",
    countryNameInEnglish: "Trinidad and Tobago",
    internationalNumber: 1868,
    twoLettersCountryCode: "TT",
    threeLettersCountryCode: "TTO",
    imsmsCode: "1868 (Trinidad and Tobago)",
  },
  {
    countryNameInKorean: "파나마",
    countryNameInEnglish: "Panama",
    internationalNumber: 507,
    twoLettersCountryCode: "PA",
    threeLettersCountryCode: "PAN",
    imsmsCode: "507 (Panama)",
  },
  {
    countryNameInKorean: "파라과이",
    countryNameInEnglish: "Paraguay",
    internationalNumber: 595,
    twoLettersCountryCode: "PY",
    threeLettersCountryCode: "PRY",
    imsmsCode: "595 (Paraguay)",
  },
  {
    countryNameInKorean: "파키스탄",
    countryNameInEnglish: "Pakistan",
    internationalNumber: 92,
    twoLettersCountryCode: "PK",
    threeLettersCountryCode: "PAK",
    imsmsCode: "92 (Pakistan)",
  },
  {
    countryNameInKorean: "파푸아뉴기니",
    countryNameInEnglish: "Papua New Guinea",
    internationalNumber: 675,
    twoLettersCountryCode: "PG",
    threeLettersCountryCode: "PNG",
    imsmsCode: "675 (Papua New Guinea)",
  },
  {
    countryNameInKorean: "팔라우",
    countryNameInEnglish: "Palau",
    internationalNumber: 680,
    twoLettersCountryCode: "PW",
    threeLettersCountryCode: "PLW",
    imsmsCode: "680 (Palau)",
  },
  {
    countryNameInKorean: "팔레스타인",
    countryNameInEnglish: "State of Palestine",
    internationalNumber: 970,
    twoLettersCountryCode: "PS",
    threeLettersCountryCode: "PSE",
    imsmsCode: "970 (State of Palestine)",
  },
  {
    countryNameInKorean: "페루",
    countryNameInEnglish: "Peru",
    internationalNumber: 51,
    twoLettersCountryCode: "PE",
    threeLettersCountryCode: "PER",
    imsmsCode: "51 (Peru)",
  },
  {
    countryNameInKorean: "포르투갈",
    countryNameInEnglish: "Portugal",
    internationalNumber: 351,
    twoLettersCountryCode: "PT",
    threeLettersCountryCode: "PRT",
    imsmsCode: "351 (Portugal)",
  },
  {
    countryNameInKorean: "포클랜드 제도",
    countryNameInEnglish: "Falkland Islands",
    internationalNumber: 500,
    twoLettersCountryCode: "FK",
    threeLettersCountryCode: "FLK",
    imsmsCode: "500 (Falkland Islands)",
  },
  {
    countryNameInKorean: "폴란드",
    countryNameInEnglish: "Poland",
    internationalNumber: 48,
    twoLettersCountryCode: "PL",
    threeLettersCountryCode: "POL",
    imsmsCode: "48 (Poland)",
  },
  {
    countryNameInKorean: "푸에르토리코",
    countryNameInEnglish: "Puerto Rico",
    internationalNumber: 1787,
    twoLettersCountryCode: "PR",
    threeLettersCountryCode: "PRI",
    imsmsCode: "1787 (Puerto Rico)",
  },
  {
    countryNameInKorean: "프랑스",
    countryNameInEnglish: "France",
    internationalNumber: 33,
    twoLettersCountryCode: "FR",
    threeLettersCountryCode: "FRA",
    imsmsCode: "33 (France)",
  },
  {
    countryNameInKorean: "피지",
    countryNameInEnglish: "Fiji",
    internationalNumber: 679,
    twoLettersCountryCode: "FJ",
    threeLettersCountryCode: "FJI",
    imsmsCode: "679 (Fiji)",
  },
  {
    countryNameInKorean: "핀란드",
    countryNameInEnglish: "Finland",
    internationalNumber: 358,
    twoLettersCountryCode: "FI",
    threeLettersCountryCode: "FIN",
    imsmsCode: "358 (Finland)",
  },
  {
    countryNameInKorean: "필리핀",
    countryNameInEnglish: "the Philippines",
    internationalNumber: 63,
    twoLettersCountryCode: "PH",
    threeLettersCountryCode: "PHL",
    imsmsCode: "63 (the Philippines)",
  },
  {
    countryNameInKorean: "헝가리",
    countryNameInEnglish: "Hungary",
    internationalNumber: 36,
    twoLettersCountryCode: "HU",
    threeLettersCountryCode: "HUN",
    imsmsCode: "36 (Hungary)",
  },
  {
    countryNameInKorean: "홍콩",
    countryNameInEnglish: "Hong Kong",
    internationalNumber: 852,
    twoLettersCountryCode: "HK",
    threeLettersCountryCode: "HKG",
    imsmsCode: "852 (Hong Kong)",
  },
];
