import { Close } from "@mui/icons-material";
import { Box, Button, Stack } from "@mui/material";
import { OutlinedButton } from "app/component/styles";
import { CenterModal, useModalToggle } from "../../component/Modal";
import {
  CloseButton,
  ModalContent,
  ModalDivider,
  ModalHead,
  ModalText,
} from "./ModalStyle";
import { useTheme } from "@mui/material/styles";
import { useTranslation } from "react-i18next";

export default function VariableButton({
  variableOn,
  toggleVariableMode,
}: any) {
  const { on, open, close } = useModalToggle();
  const theme = useTheme();
  const isBright = theme.palette.mode === "light";

  const { t } = useTranslation("MessagePage");

  return (
    <>
      {isBright ? (
        <OutlinedButton
          sx={{
            flex: 1,
            backgroundColor: variableOn ? "#f1f4ff" : "transparent",
          }}
          onClick={() => {
            if (!variableOn) {
              open();
            }
            toggleVariableMode();
          }}
        >
          {t("variables message")}
        </OutlinedButton>
      ) : (
        <Button
          variant="contained"
          color="secondary"
          sx={{
            flex: 1,
            backgroundColor: variableOn ? "#5818DF" : "#666",
            boxShadow: "none",
            fontSize: 14,
            height: 50,
          }}
          onClick={() => {
            if (!variableOn) {
              open();
            }
            toggleVariableMode();
          }}
        >
          {t("variables message")}
        </Button>
      )}
      <CenterModal open={on} onClose={close}>
        <ModalContent>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            sx={{ py: 2 }}
          >
            <span style={{ width: 22 }} />
            <ModalHead>{t("variables message")}</ModalHead>
            <CloseButton size="small" onClick={close}>
              <Close sx={{ color: "#ffffff", width: 18, height: 18 }} />
            </CloseButton>
          </Stack>
          <ModalDivider />
          <Stack direction="row" justifyContent="space-around" sx={{ py: 2 }}>
            {["#name#", "#1#", "#2#", "#3#", "#4#"].map(v => (
              <ModalHead key={v}>{v}</ModalHead>
            ))}
          </Stack>
          <ModalDivider />
          <Box sx={{ p: 2 }}>
            <ModalText>
              {t(
                "Please select the automatic change information you want to use from the table.",
              )}
              <br />
              {t(
                "Auto change is a function used when sending names or coupon numbers individually. - 2",
              )}
              <br />
              <br />
              <span>
                {t("example)")}
                <br />
                {t("① Insert #name# into the content")}
                <br />
                {t(
                  "② Enter or upload name data such as Tanaka and Hiro in the ‘name’ column (column A) in Excel.",
                )}
                <br />
                {t(
                  "③The data in column A is automatically inserted and transmitted in #name#.",
                )}
              </span>
            </ModalText>
          </Box>
        </ModalContent>
      </CenterModal>
    </>
  );
}
