import { call, put, select } from "@redux-saga/core/effects";
import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import axios from "axios";
import _ from "lodash";
import { logger } from "utils/helper";
import { getUser, refreshUserSaga } from "./userSlice";

export const initialState = {
  accessToken: "",
  refreshToken: "",
  refreshing: false,
};

const slice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    changeTokens(
      state,
      action: PayloadAction<{
        accessToken: string;
        refreshToken: string;
      }>,
    ) {
      state.accessToken = action.payload.accessToken;
      state.refreshToken = action.payload.refreshToken;
    },
    toggleRefreshing(state) {
      state.refreshing = !state.refreshing;
    },
    loadTokens() {},
  },
});

export const { actions: authActions, reducer: authReducer } = slice;

const selectDomain = state => state.auth || initialState;

export const selectAccessToken = createSelector(
  [selectDomain],
  authState => authState.accessToken,
);

export const selectRefreshToken = createSelector(
  [selectDomain],
  authState => authState.refreshToken,
);

export const selectRefreshing = createSelector(
  [selectDomain],
  authState => authState.refreshing,
);

export const REFRESH_ACCESS_TOKEN = "REFRESH_ACCESS_TOKEN";

function getPutToken(payload) {
  return axios({
    method: "PUT",
    url: `/v1/tokens/`,
    data: payload,
  });
}
export function* refreshTokenSaga() {
  try {
    yield put(authActions.toggleRefreshing());
    const refreshToken = yield select(selectRefreshToken);

    const token = refreshToken;
    if (!token) {
      logger.log("NO TOKEN");
      return;
    }
    const payload = {
      refreshToken: token,
    };
    logger.log("TOKEN REFRESHING", payload);

    const res = yield call(getPutToken, payload);

    const tokens = {
      accessToken: _.get(res, "data.data.accessToken"),
      refreshToken: _.get(res, "data.data.refreshToken"),
    };
    const action = authActions.changeTokens(tokens);
    logger.log("REFRESH RESULT", res, action);
    yield put(action);
    yield getUser(tokens.accessToken);
    return tokens;
  } catch (err) {
    const action = authActions.changeTokens({
      accessToken: "",
      refreshToken: "",
    });
    logger.log("REFRESH INIT", err, action);
    alert("로그인이 만료됐습니다.");
    yield put(action);
  } finally {
    yield put(authActions.toggleRefreshing());
  }
}

export const CHANGE_TOKENS = "CHANGE_TOKENS";
export function* changeTokensSaga({ type, payload }) {
  yield put(authActions.changeTokens(payload));
  yield refreshUserSaga();
}
