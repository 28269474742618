import { Navigate, useLocation, useRoutes } from "react-router-dom";
import Page404 from "app/pages/Page404";
import LoginPage from "./pages/LoginPage";
import AccountPage from "./pages/Account/AccountPage";
import MessagePage from "./pages/Message/MessagePage";
import { useDispatch } from "react-redux";
import React from "react";
import { HYDRATE_APP } from "./store/configureStore";

export default function Router() {
  const routes = [
    { path: "/404/", element: <Page404 /> },
    {
      path: "/",
      element: <LoginPage />,
    },
    {
      path: "/login/",
      element: <LoginPage />,
    },
    {
      path: "/account/",
      element: <AccountPage />,
    },
    {
      path: "/message/",
      element: <MessagePage />,
    },
    { path: "*", element: <Navigate to="/404/" replace /> },
  ];

  const dispatch = useDispatch();
  const { pathname } = useLocation();
  React.useEffect(() => {
    dispatch({ type: HYDRATE_APP });
  }, [pathname, dispatch]);

  return useRoutes(routes);
}
