import { MenuItem, Select } from "@mui/material";
import { useTranslation } from "react-i18next";

export function LanguageSelect() {
  const { i18n } = useTranslation();
  return (
    <Select
      size="small"
      value={i18n.language}
      onChange={e => i18n.changeLanguage(e.target.value)}
    >
      <MenuItem value={"jp"}>日本語</MenuItem>
      <MenuItem value={"en"}>English</MenuItem>
    </Select>
  );
}
