import { Clear } from "@mui/icons-material";
import { Divider, Stack, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import {
  AddressIconButton,
  DarkAddressIconButton,
  ListHeader,
} from "../address/ListStyle";
import { PhoneChip } from "./PhoneChip";
import { useTranslation } from "react-i18next";

export function PhoneChipList({ variableOn, form }) {
  const { t } = useTranslation("MessagePage");

  const msgs = form.watch("msgs");
  const theme = useTheme();
  const isBright = theme.palette.mode === "light";
  return (
    <Stack
      sx={{
        px: 2,
        border: "1px solid #E6E6E6",
        borderRadius: "6px",
        bgcolor: isBright ? "transparent" : "#333333",
      }}
    >
      <Stack
        sx={{
          minHeight: 160,
          maxHeight: 320,
          overflow: "scroll",
          "::-webkit-scrollbar": {
            display: "none",
          },
          pt: 2,
          pl: variableOn ? 2 : "none",
        }}
      >
        {variableOn ? (
          <ListHeader width={150} props={{ sx: { flex: 1, p: 0 } }} />
        ) : (
          <></>
        )}

        {msgs.map((contact: any, i: any) => (
          <PhoneChip
            key={i}
            form={form}
            contact={contact}
            variableOn={variableOn}
            index={i}
          />
        ))}
      </Stack>
      <Divider />
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        sx={{ py: 1 }}
      >
        {isBright ? (
          <AddressIconButton
            variant="text"
            endIcon={<Clear sx={{ width: 12, height: 12 }} />}
            onClick={() => form.setValue("msgs", [])}
            disabled={msgs.length === 0}
          >
            {t("reset")}
          </AddressIconButton>
        ) : (
          <DarkAddressIconButton
            variant="text"
            endIcon={<Clear sx={{ width: 12, height: 12 }} />}
            onClick={() => form.setValue("msgs", [])}
            disabled={msgs.length === 0}
          >
            {t("reset")}
          </DarkAddressIconButton>
        )}

        <Typography sx={{ fontSize: 14 }}>
          {t("Total ")}
          {msgs.length}
          {t(" items")}
        </Typography>
      </Stack>
    </Stack>
  );
}
