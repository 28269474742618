import { Card, CardContent, Stack, Typography } from "@mui/material";
import React from "react";
import { CenterModal } from "./Modal";
import LoadingButton from "@mui/lab/LoadingButton";

export function OkModal({
  on,
  title = "",
  loading = false,
  confirmText = "確認",
  children = <></>,
  onClose,
}: {
  on: boolean;
  title?: string | React.ReactNode;
  loading?: boolean;
  confirmText?: string | React.ReactNode;
  children?: string | React.ReactNode;
  onClose: any;
}) {
  return (
    <>
      <CenterModal open={on} onClose={onClose}>
        <Card sx={{ width: 380 }}>
          <CardContent sx={{ p: 0, pt: 4 }}>
            <Stack alignItems="center">
              <Typography variant="h5" textAlign="center">
                {title}
              </Typography>
            </Stack>

            {children}
            <Stack direction="row" justifyContent="center" spacing={1}>
              <LoadingButton
                variant="contained"
                color="primary"
                loading={loading || false}
                style={{ boxShadow: "none" }}
                sx={{
                  width: 200,
                  height: 36,
                  fontWeight: 700,
                  fontSize: 12,
                  color: "#ffffff",
                  "&:hover,&:active": {
                    background: "#5818DF",
                    color: "#ffffff",
                  },
                  mt: "16px",
                }}
                onClick={onClose}
              >
                {confirmText}
              </LoadingButton>
            </Stack>
          </CardContent>
        </Card>
      </CenterModal>
    </>
  );
}
