const en = {
  Global: {
    "About Us": "About Us",
  },
  LoginPage: {
    "Please enter your ID": "Please enter your ID",
    "Maximum 100 digits": "Maximum 100 digits",
    "Please enter your password": "Please enter your password",
    "Maximum 200 digits": "Maximum 200 digits",
    LOGIN: "LOGIN",
    "Please enter your": "Please enter your",
    "ID and password": "ID and password",
    "Please enter ID": "Please enter ID",
    "Please enter password": "Please enter password",
    "Signup for newsletter": "Signup for newsletter",
    "Login failed": "Login failed",
    "Please check your user ID or PW.": "Please check your user ID or PW.",
    "Waiting to sign up.": "Waiting to sign up.",
    "It will take some time to post your demo account.":
      "It will take some time to post your demo account.",
    "Issuance takes 1 to 3 days.": "Issuance takes 1 to 3 days.",
  },
  AccountPage: {
    "Email is incorrect.": "Email is incorrect.",
    "Email must be at least 7 characters long.":
      "Email must be at least 7 characters long.",
    "Email must be 100 characters or less.":
      "Email must be 100 characters or less.",
    "The person in charge's name has more than 2 characters.":
      "The person in charge's name has more than 2 characters.",
    "The contact person's name must be 50 characters or less.":
      "The contact person's name must be 50 characters or less.",
    "The contact information must be at least 7 characters long.":
      "The contact information must be at least 7 characters long.",
    "The contact information must be 20 characters or less.":
      "The contact information must be 20 characters or less.",
    "Please enter only numbers without - for contact information.":
      "Please enter only numbers without - for contact information.",
    "Please enter all letters, numbers, and special characters for your password":
      "Please enter all letters, numbers, and special characters for your password",
    // 'Password is more than 5 digits long':
    //   'Password is more than 5 digits long',
    // 'Password can be up to 200 characters long':
    //   'Password can be up to 200 characters long',
    "Password does not match.": "Password does not match.",
    "The store (company) name must be at least 2 characters.":
      "The store (company) name must be at least 2 characters.",
    "The store (company) name must be 50 characters or less.":
      "The store (company) name must be 50 characters or less.",
    "The brand store (company) name has at least 2 characters.":
      "The brand store (company) name has at least 2 characters.",
    "The brand store (company) name must be 50 characters or less.":
      "The brand store (company) name must be 50 characters or less.",
    "Business address must be at least 2 characters long.":
      "Business address must be at least 2 characters long.",
    "Business address must be 100 characters or less.":
      "Business address must be 100 characters or less.",
    "The representative name has more than 2 characters.":
      "The representative name has more than 2 characters.",
    "The representative name must be 50 characters or less.":
      "The representative name must be 50 characters or less.",
    "Representative contact information must be at least 7 characters long.":
      "Representative contact information must be at least 7 characters long.",
    "Representative contact information must be 20 characters or less.":
      "Representative contact information must be 20 characters or less.",
    // 'Please attach the pledge.': 'Please attach the pledge.',
    "ID(Email)  *Required": "ID(Email)  *Required",
    "Company name  *Required": "Company name  *Required",
    "Brand name  *Required": "Brand name  *Required",
    // "Representative name": "Representative name",
    // "Representative contact information": "Representative contact information",
    name: "name",
    "phone number": "phone number",
    // "Attachment of pledge": "Attachment of pledge",
    "Signup for newsletter": "Signup for newsletter",
    "* is Required": "* is Required",
    "After you sign up for our newsletter, we will also contact you separately and provide you with a demo account. Issuing a demo account takes 1 to 3 days.":
      "After you sign up for our newsletter, we will also contact you separately and provide you with a demo account. Issuing a demo account takes 1 to 3 days.",
    "I agree to create a free demo account and send newsletters.":
      "I agree to create a free demo account and send newsletters.",
    "Please enter only numbers": "Please enter only numbers",
    "This is a required option.": "This is a required option.",
    complete: "complete",
    "Welcome!": "Welcome!",
    "Please log in": "Please log in",
    "registration failed": "registration failed",
    "This email address has already been registered.":
      "This email address has already been registered.",
  },
  MessagePage: {
    "Select Channel": "Select Channel",
    "Select the sending number to send to":
      "Select the sending number to send to",
    [`Please enter messages. “Send various messages using templates and variables.”`]: `Please enter messages. “Send various messages using templates and variables.”`,
    "message templates": "message templates",
    "variables message": "variables message",
    "Please select the automatic change information you want to use from the table.":
      "Please select the automatic change information you want to use from the table.",
    "Auto change is a function used when sending names or coupon numbers individually. - 2":
      "Auto change is a function used when sending names or coupon numbers individually.",
    "example)": "example)",
    "① Insert #name# into the content": "① Insert #name# into the content",
    "② Enter or upload name data such as Tanaka and Hiro in the ‘name’ column (column A) in Excel.":
      "② Enter or upload name data such as Tanaka and Hiro in the ‘name’ column (column A) in Excel.",
    "③The data in column A is automatically inserted and transmitted in #name#.":
      "③The data in column A is automatically inserted and transmitted in #name#.",
    "Receive number": "Receive number",
    addition: "Add.",
    upload: "upload",
    "address book": "address book",
    "Address book management": "Address book management",
    "Reserved delivery": "Reserved delivery",
    "Year Month Day": "Year Month Day",
    hour: "hour",
    minute: "minute",
    "Send message": "Send message",
    "Sent message history": "Sent message history",
    [`welcome. Dear #name#.
    If you come to our OOO store today, we will offer you a beer for 100 yen until 8pm.`]: `welcome. Dear #name#.
    If you come to our OOO store today, we will offer you a beer for 100 yen until 8pm.`,
    [`"congratulations!
    “If you receive this message, come to the OOO store today and you will receive a free plate of yakitori.”`]: `"congratulations!
    “If you receive this message, come to the OOO store today and you will receive a free plate of yakitori.”`,
    [`Thank you for visiting us today.
    Did you like the food?
    We will continue to do our best to provide delicious and high quality food.`]: `Thank you for visiting us today.
    Did you like the food?
    We will continue to do our best to provide delicious and high quality food.`,
    attachment: "attachment",
    Preview: "Preview",
    "phone number": "phone number",
    "phone number-v2": "phone number",
    reset: "reset",
    "Send history": "Send history",
    "Only messages sent in the last 30 days will be displayed.":
      "Only messages sent in the last 30 days will be displayed.",
    "send date": "send date",
    attach: "attach",
    result: "result",
    "1day": "1day",
    "15day": "15day",
    "1month": "1month",
    "export to excel": "export to excel",
    close: "close",
    Requested: "Requested",
    "Total ": "Total ",
    " items": " items",
    "Please enter only numbers.": "Please enter only numbers.",
    "Please enter your mobile phone number":
      "Please enter your mobile phone number",
    "Please write within 15 digits.": "Please write within 15 digits.",
    "Please enter your message.": "Please enter your message.",
    "Maximum 400 characters": "Maximum 400 characters",
    "Please enter after the current time":
      "Please enter after the current time",
    "Please choose a date": "Please choose a date",
    "Transfer completed": "Transfer completed",
    "Transfer failed": "Transfer failed",
    "Transfer resereved.": "Transfer resereved.",
    "Please enter the variable": "Please enter the variable",
    "Please enter your contact information.":
      "Please enter your contact information.",
    "You can enter up to 100 contacts.": "You can enter up to 100 contacts.",
    "Please select a sender number": "Please select a sender number",
    " Contact": " Contact",
  },
};

export default en;
