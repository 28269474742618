import { Add } from "@mui/icons-material";
import { Box, Stack } from "@mui/material";
import { IconButton, Label } from "app/component/styles";
import produce from "immer";
import { useRef } from "react";
import { useTranslation } from "react-i18next";

export function ImageInput({ form }: any) {
  const { t } = useTranslation("MessagePage");
  const fileInpRef = useRef<any>();
  const images = form.watch("images");

  function onSelect(e: any) {
    e.preventDefault();

    if (!!e.target.files[0]) {
      const reader = new FileReader();
      reader.readAsDataURL(e.target.files[0]);
      reader.onload = function () {
        const newImages = produce(images, (draft: any) => {
          draft.push({
            file: e.target.files[0],
            image: reader.result,
            input: e.target,
          });
        });
        form.setValue("images", newImages);
      };
    }
  }

  return (
    <>
      <Label>{t("attachment")}</Label>
      {images.length < 1 && (
        <IconButton
          color="secondary"
          onClick={() => {
            fileInpRef.current.click();
          }}
          sx={{
            height: 50,
            maxWidth: 50,
            minWidth: 50,
            border: "1px solid #E6E6E6",
            borderRadius: "6px",
          }}
        >
          <Add sx={{ color: "#333333" }} />
        </IconButton>
      )}
      <input
        style={{ display: "none" }}
        type="file"
        accept="image/png, image/jpeg, image/jpg"
        ref={fileInpRef}
        onChange={onSelect}
        multiple={false}
      />

      {images.map((image: any, index) => (
        <Box sx={{ position: "relative" }}>
          <Box
            component="img"
            sx={{
              height: 50,
              width: 50,
              border: "1px solid #E6E6E6",
              borderRadius: "6px",
            }}
            // alt={image.file.name || ''}
            src={image.image}
          />
          <Stack
            sx={{
              position: "absolute",
              width: 28,
              height: 28,
              top: 4,
              right: 4,
              cursor: "pointer",
            }}
            direction="row"
            justifyContent={"flex-end"}
          >
            <Box
              component="img"
              src={require("app/media/img_del.png")}
              sx={{ width: 14, height: 14 }}
              onClick={() => {
                const newImages = produce(images, draft => {
                  draft.splice(index, 1);
                });
                form.setValue("images", newImages);
                fileInpRef.current.value = null;
              }}
            />
          </Stack>
        </Box>
      ))}
    </>
  );
}
