import { Divider, IconButton, Stack, styled, Typography } from "@mui/material";

export const ModalContent = styled(Stack)`
  border: 1px solid #000000;
  border-radius: 6px;
  background-color: #5818df;
  color: #ffffff;
  max-width: 800px;
  height: 50%;
  overflow-y: scroll;
  ::-webkit-scrollbar {
    display: none;
  }
`;

export const ModalDivider = styled(Divider)`
  border: 1px solid #000000;
`;

export const ModalHead = styled(Typography)`
  font-size: 14px;
  font-weight: 700;
  line-height: 24px;
`;

export const ModalText = styled(Typography)`
  font-size: 13px;
  font-weight: 400;
  line-height: 24px;
  span {
    font-weight: 700;
  }
`;

export const CloseButton = styled(IconButton)`
  color: #5818df;
  margin-right: 16px;
  &:hover,
  &:active {
    color: #5818df;
  }
`;
