import { Modal as MtModal, Typography } from "@mui/material";
import _ from "lodash";
import { useCallback } from "react";
import { useToggle } from "react-use";
import { styled } from "@mui/material/styles";

export const ModalText = styled(Typography)`
  font-size: 14px;
  font-weight: 700;
`;

export const CenterModal = styled<any>(MtModal)`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px;
  & > .MuiBackdrop-root {
    background: none !important;
    background-color: none !important;
  }

  span:focus {
    outline: 0;
  }

  ${({ bound }) =>
    bound
      ? `
  & > div > * {
    max-height: calc(100vh - 120px);
    overflow-y: scroll;
  }
  
  `
      : ""}
`;

export function useModalToggle(onClose = _.noop) {
  const [on, toggle] = useToggle(false);

  const open = useCallback(() => {
    toggle(true);
  }, [toggle]);

  const close = useCallback(() => {
    toggle(false);
    onClose();
  }, [toggle, onClose]);

  return { on, open, close, toggle };
}
