import { Global } from "@emotion/react";
import { Stack } from "@mui/material";
import Box from "@mui/material/Box";
import { grey } from "@mui/material/colors";
import { styled } from "@mui/material/styles";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import Typography from "@mui/material/Typography";
import * as React from "react";
import { getByteLength } from "utils/helper";
import { TransparentField } from "./MessageContent";
import { useTheme } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
const drawerWidth = 260;

const StyledBox = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "light" ? "#5818DF" : grey[800],
}));

const TransparentDrawer = styled(SwipeableDrawer)`
  & > .MuiBackdrop-root {
    background: none !important;
    background-color: none !important;
  }
`;

export default function MessageDrawer({ form, template }: any) {
  const { t } = useTranslation("MessagePage");

  const theme = useTheme();
  const isBright = theme.palette.mode === "light";
  const [open, setOpen] = React.useState(false);

  const toggleDrawer = (newOpen: boolean) => () => {
    setOpen(newOpen);
  };

  return (
    <>
      <Stack
        sx={{
          bgcolor: isBright ? "#F1F4FF" : "#333333",
          p: 2,
          borderRadius: "6px",
          mt: 2,
          display: { xs: "inline", md: "none" },
        }}
      >
        <TransparentField
          sx={{ m: 0 }}
          InputProps={{ style: { paddingBottom: 32, flex: 1 } }}
          multiline={true}
          minRows={15}
          fullWidth={true}
          inputProps={form.register("template")}
          onChange={e => {
            if (getByteLength(template) <= 2000) {
              form.setValue("template", e.target.value);
            }
          }}
          placeholder={t(
            `Please enter messages. “Send various messages using templates and variables.”`,
          )}
        />
        <Stack
          direction="row"
          justifyContent="flex-end"
          sx={{ borderTop: "1px solid #E6E6E6", ml: 2, mt: 1 }}
        >
          <Typography sx={{ pt: 2, pb: 1 }}>{`${getByteLength(
            template,
          )}/2000 byte`}</Typography>
        </Stack>
      </Stack>
      {open ? (
        <></>
      ) : (
        <DrawerButton
          onClick={toggleDrawer(true)}
          props={{ top: 200, display: { xs: "inline", md: "none" } }}
        />
      )}
      <Global
        styles={{
          ".MuiDrawer-root > .MuiPaper-root": {
            overflow: "visible",
            minWidth: drawerWidth,
            maxWidth: 300,
            backgroundColor: "#5818DF !important",
            maxHeight: "56%",
            borderRadius: 6,
            borderTopLeftRadius: 0,
            top: 200,
            boxShadow: "none",
          },
        }}
      />

      <TransparentDrawer
        anchor="right"
        open={open}
        onClose={toggleDrawer(false)}
        onOpen={toggleDrawer(true)}
        disableSwipeToOpen={true}
        ModalProps={{
          keepMounted: true,
        }}
        sx={{ display: { xs: "inline", md: "none" } }}
      >
        {open ? (
          <DrawerButton
            onClick={toggleDrawer(true)}
            position="fixed"
            props={{
              right: 295,
              display: { xs: "inline", md: "none" },
            }}
          />
        ) : (
          <></>
        )}

        <StyledBox
          sx={{
            px: 2,
            height: "100%",
            minWidth: 300,
            borderRadius: "6px",
            bgcolor: "#5818DF",
          }}
        >
          <Stack
            sx={{
              bgcolor: isBright ? "#fff" : "#666",
              border: "2px solid black",
              borderRadius: "6px",
              mt: 2,
              mb: 3,
              p: 3,
              height: "80%",
              overflowY: "scroll",
              "::-webkit-scrollbar": {
                display: "none",
              },
            }}
          >
            <Typography sx={{ mt: 1.5 }}>
              {template ? (
                template.split("\n").map((line, i) => (
                  <>
                    {i > 0 && <br />}
                    {line}
                  </>
                ))
              ) : (
                <>
                  {t(
                    "Please enter messages. “Send various messages using templates and variables.”",
                  )}
                  {/* 内容を入力してください。
                  <br />
                  テンプレートと変数を使用して、
                  <br />
                  さまざまなメッセージを配信しましょう。 */}
                </>
              )}
            </Typography>
          </Stack>
        </StyledBox>
      </TransparentDrawer>
    </>
  );
}

function DrawerButton({ onClick, position = "absolute", props = {} }) {
  const { t } = useTranslation("MessagePage");

  return (
    <StyledBox
      sx={{
        position,
        borderTopLeftRadius: 8,
        borderBottomLeftRadius: 8,
        visibility: "visible",
        right: 0,
        width: 50,
        height: 101,
        bgcolor: "#5818DF",
        ...props,
      }}
      onClick={onClick}
    >
      <Stack justifyContent="center" alignItems="center" sx={{ py: 1.5 }}>
        <img
          src={require("app/media/phone.png")}
          alt="미리보기 이미지"
          style={{
            width: 23,
            height: 20,
          }}
        />
        <Typography
          sx={{
            p: 1,
            fontSize: 12,
            fontWeight: 700,
            lineHeight: "15px",
            color: "#ffffff",
          }}
        >
          {t("Preview")}
          {/* プレ
          <br />
          ビュー */}
        </Typography>
        <img
          src={require("app/media/veiw_open.png")}
          alt="미리보기 이미지"
          style={{
            width: 14,
            height: 14,
          }}
        />
      </Stack>
    </StyledBox>
  );
}
