import PropTypes from "prop-types";
import { useEffect, useMemo, useState } from "react";
// material
import { CssBaseline } from "@mui/material";
import {
  createTheme,
  StyledEngineProvider,
  ThemeProvider,
} from "@mui/material/styles";
//
import componentsOverride from "./overrides";
import shadows, { customShadows } from "./shadows";
import typography from "./typography";
import darkPalette from "./darkPalette";
import brightPalette from "./brightPalette";

ThemeConfig.propTypes = {
  children: PropTypes.node,
};

export default function ThemeConfig({ children }) {
  const [mode, setMode] = useState(brightPalette);
  useEffect(() => {
    window
      .matchMedia("(prefers-color-scheme: dark)")
      .addEventListener("change", event => {
        const mode = event.matches ? darkPalette : brightPalette;

        setMode(mode);
      });
  }, []);

  const themeOptions = useMemo(
    () => ({
      palette: mode,
      shape: { borderRadius: 8 },
      typography,
      shadows,
      customShadows,
    }),
    [mode],
  );

  const theme = createTheme(themeOptions);
  theme.components = componentsOverride(theme);

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        {children}
      </ThemeProvider>
    </StyledEngineProvider>
  );
}
